import React, { useState, useEffect } from "react";
import { Col, Modal, ModalFooter } from "react-bootstrap";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import { CardTopUpPayload } from "../../../../redux/toolkit/cards/interface";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { cardTopUp, getAllCards } from "../../../../redux/toolkit/cards/operation";
import cardSelectors from "../../../../redux/toolkit/cards/selector";
import Swal from "sweetalert2";
import { FormattedCard, LoadCardData, SelectedBusinessAccount } from "../../interface";
import BasicButton from "../../../../components/shared/Buttons/Basic";
import { ErrorImage, SuccessImage } from "../../../../constants/Images";
import { AiOutlineArrowLeft } from "react-icons/ai";

const TopUpSummaryModal = (props: {
  showSummaryModal: boolean,
  loadCardData: LoadCardData,
  setLoadCardData: React.Dispatch<React.SetStateAction<LoadCardData>>;
  selectedCard: FormattedCard,
  handleClose: () => void,
  selectedBusinessAcc: SelectedBusinessAccount;
  setShowAddMoneySummary: React.Dispatch<React.SetStateAction<boolean>>
  setShowAddMoney: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const { showSummaryModal, loadCardData, setLoadCardData, selectedCard, handleClose, selectedBusinessAcc, setShowAddMoneySummary, setShowAddMoney } = props;
  const dispatch = useDispatch<AppDispatch>();
  const userCurrency = selectedCard.currency;

  const cardTopUpSelector = useSelector(cardSelectors.cardTopUpSelect);

  const handleAccept = () => {
    // the topUpAmount is sent as the full amount the user entered.
    // The fee is calculated and deducted by B4B
    const topUpPayload: CardTopUpPayload = {
      userId: localStorage.getItem("userId")!,
      card_code: selectedCard.cardId,
      amount: String(loadCardData.amount),
      uuid: selectedBusinessAcc?.accB4BUUID || "",
      currency: userCurrency,
      description: loadCardData.description
    }
    dispatch(cardTopUp(topUpPayload))
      .unwrap()
      .then((resp) => {
        // dispatch(getAllCards({ userId: localStorage.getItem('userId')! }))
        Swal.fire({
          // icon: "success",
          imageUrl: SuccessImage,
          title: "Success",
          confirmButtonColor: "#86B6EF",
          text: `Your card has been topped up with ${userCurrency} ${loadCardData.amount}`,
          customClass: {
            confirmButton: 'modalBtnOK'
          }
        }).then(() => {
          setLoadCardData({ amount: 0, description: "" });
          handleClose();
        });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          // icon: "error",
          imageUrl: ErrorImage,
          title: "Error",
          // confirmButtonColor: "#86B6EF",
          text: "Unable to topup card, please try again!",
          customClass: {
            confirmButton: 'modalBtnOK'
          }
        }).then(() => {
          setLoadCardData({ amount: 0, description: "" });
          handleClose();
        });
      })
  }

  return (
    <Modal
      show={showSummaryModal}
      centered
      onHide={() => {
        setLoadCardData({ amount: 0, description: "" });
        return handleClose();
      }}
    >
      <Modal.Header style={{ display: 'flex', alignItems: 'center', borderBottom: 0 }}>
        <div style={{ display: "flex", alignItems: 'center' }}>
          <div className="goBackIcon" style={{ display: "flex" }}
            onClick={() => {
              setShowAddMoneySummary(false)
              setShowAddMoney(true)
            }}
          >
            <AiOutlineArrowLeft />
          </div>
          <Modal.Title>Summary</Modal.Title>
        </div>
        <IconButton
          style={{ backgroundColor: '#006ff4', color: 'white', width: '22px', height: '22px' }}
          onClick={() => {
            setLoadCardData({ amount: 0, description: "" });
            return handleClose();
          }}
        >
          <CloseIcon style={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Modal.Header>

      <Modal.Body className="pt-2 pb-2">

        <p style={{ fontSize: "14px", margin: 0, color: "#ADB6C0" }}>Business Account</p>
        <p style={{ marginTop: "5px", marginBottom: "10px", fontWeight: "600" }}>{selectedBusinessAcc.accountName}</p>

        <p style={{ fontSize: "14px", margin: 0, color: "#ADB6C0" }}>Top-up Amount</p>
        <p style={{ marginTop: "5px", marginBottom: "10px", fontWeight: "600" }}>{loadCardData.amount} {userCurrency}</p>

        <p style={{ fontSize: "14px", margin: 0, color: "#ADB6C0" }}>Load Description</p>
        <div style={{ maxHeight: "fit-content" }}>
          <p style={{ marginTop: "5px", marginBottom: "10px", fontWeight: "600", overflowWrap: "break-word" }}>{loadCardData.description || `-`}</p>
        </div>

        <p style={{ fontSize: "10px", margin: 0, color: "#ADB6C0" }}>* Please note that a standard fee applies to all card top-ups</p>
      </Modal.Body>

      <ModalFooter style={{ borderTop: "0px", paddingTop: 0 }}>
        <div style={{ display: 'flex', width: '100%' }}>
          <Col className="col-6 d-flex justify-content-center">
            <BasicButton
              type="button"
              style={{ width: '95%' }}
              onClick={handleAccept}
              text="Accept"
              isLoading={cardTopUpSelector.status === "loading"}
              spinnerVariant="sm"
            />
          </Col>

          <Col className="col-6 d-flex justify-content-center">
            <BasicButton
              type="button"
              style={{
                width: '95%',
                backgroundColor: "white",
                color: "#006ff4",
                border: "1px solid #006ff4",
              }}
              onClick={handleClose}
              text="Reject"
              disabled={cardTopUpSelector.status === "loading"}
            />
          </Col>
        </div>

      </ModalFooter>
    </Modal>
  )
};

export default TopUpSummaryModal;