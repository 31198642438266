import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { format, isValid } from "date-fns";
import { CircularProgress } from '@mui/material'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const currencySymbolMap = require("currency-symbol-map");
// import { getSymbolFromCurrency } from 'currency-symbol-map';
// import { updatePaymentStatus } from "../../../redux/toolkit/sendFunds/operations";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
// import { Spinner } from "react-bootstrap";
// import Card from "react-bootstrap/Card";
// import { Row, Col, } from "react-bootstrap";
// import { customToolBarIcons } from "../../../constants/stringConstants/muiDataTable";
import {customToolBarIcons} from '../../shared/MuiDataTable/ToolbarIcons'
import CustomPagination from "../CustomPagination";


export const CustomSchedulingDataTable = ({ data, SendScheduledFundsData, getSchPayments }: any) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [loadingId, setLoadingId] = useState("");
    const [currentPage, setCurrentPage] = useState<number>(0);
    const dispatch = useDispatch<AppDispatch>();

    const getMuiTheme = () =>
        createTheme({
            components: {
                MuiTableCell: {
                    styleOverrides: {
                        "root": {
                            padding: '10px',
                            fontFamily: 'Montserrat',
                            fontSize: 18,
                            // backgroundColor: '#CDCAC6',
                        }
                    }
                },
                MuiButton: {
                    styleOverrides: {
                        root: {
                            fontFamily: 'Montserrat',
                            // fontSize:14,
                            fontWeight: "600",
                            textAlign: 'left',
                            paddingLeft: 0
                        },
                    }
                },
                MUIDataTableHeadCell: {
                    styleOverrides: {
                        root: {
                            fontWeight: "500 !important",
                        }

                    }
                },
                // MuiToolbar: {
                //   styleOverrides:{
                //     regular: {
                //     minHeight: '8px',
                //   }}
                // }
                MuiList:{
                    styleOverrides: {
                        root: {
                            display:"flex",
                            flexDirection:'column',
                            background:'#ffffff',
                        }
                    }
                },
                MuiTypography: {
                    styleOverrides: {
                      root: {
                        fontFamily: 'Montserrat',
                        fontSize: '18px',
                        fontWeight: 500,
                        color: '#ADB6C0',
                      }
                    }
                  },
                  MUIDataTableFilter:{
                    styleOverrides:{
                      root:{fontFamily:'Montserrat'},
                      reset:{
                        // backgroundColor:"red",
                        '& button': { 
                          marginLeft:'10px',
                          paddingLeft:"6px",
                        }
                      },
                    }
                  },
                  MuiFormControl:{
                    styleOverrides:{
                      root:{
                        padding:'35px 20px 20px 20px'
                      }
                    }
                  },
            }
        });

    const columns = [
        {
            name: "createdAt",
            label: "Date / Day",
            options: {
                filter: true, sort: false,
                customBodyRenderLite: (dataIndex: any) => {
                    return <div style={{ minWidth: "max-content" }} >{
                        isValid(new Date(data[dataIndex]?.createdAt)) ?
                            format(new Date(data[dataIndex]?.createdAt), "yyyy-MM-dd") :
                            data[dataIndex]?.createdAt
                    }
                    </div>
                }
            },
        },
        {
            name: "payment_reason",
            label: "Reason",
            options: { filter: true, sort: false },
        },
        {
            name: "payment_amount",
            label: "Amount",
            options: {
                filter: true, sort: false,
                customBodyRenderLite: (dataIndex: any) => {
                    return <div style={{ minWidth: "55px" }} >{data[dataIndex]?.payment_amount}</div>
                }
            },
        },
        {
            name: "payment_currency",
            label: "Currency",
            options: { filter: true, sort: false },
        },
        {
            name: "description",
            label: "Description",
            options: { filter: true, sort: false },
        },
        {
            name: "status",
            label: "Status",
            options: { filter: true, sort: false },
        },
        {
            name: "payment_amount",
            label: "Action",
            options: {
                filter: true, sort: false,
                customBodyRenderLite: (dataIndex: any) => {
                    return <button
                        className="btn-transfer"
                        style={{ background: data[dataIndex]?.isSchApproved ? "#006FF4" : "#f40f00",  cursor: isLoading ? "" : "pointer"}}
                        onClick={() => {
                            if (data[dataIndex]?.isSchApproved === false)
                                approveHandler(data[dataIndex])
                        }}
                        disabled={isLoading}
                    >
                        {/* {(isLoading && data[dataIndex]) && <Spinner animation={"border"} size="sm" style={{marginRight:"5px"}}/> } */}
                        {
                           data[dataIndex]._id === loadingId && isLoading ? (
                                <CircularProgress color="inherit" size={window.innerWidth < 576 ? 8: 15} />
                            ): (
                                !data[dataIndex]?.isSchApproved ? "Approve" : "Transfered"
                            )
                        }                        
                    </button>
                }
            },
        },
    ];

    const approveHandler = async (data: any) => {
        try {
            setIsLoading(true)
            setLoadingId(data?._id);
            // console.log("approveHandler =>", data);
            // const payload = { id: data._id, status: data.isRecurring ? false : true }
            const payload = {
                beneficiary_uuid: data?.beneficiary_uuid,
                description: data?.description,
                payment_reference: data?.payment_reference,
                payment_amount: data?.payment_amount,
                payment_currency: data?.payment_currency,
                payment_reason: data?.payment_reason,
                uuid: data?.uuid,
                userID: data?.userID,
                id: data?._id
            }
            const res = await dispatch(SendScheduledFundsData(payload))
            await getSchPayments();
            // console.log(res)
            setIsLoading(false)
            setLoadingId("");
        } catch (error) {
            setIsLoading(false)
            setLoadingId("");
        }
    }


    return (
        <>
            {/* <Row> */}
            {/* Content area collomns */}
            {/* <Col sm={12} md={12} lg={12}> */}
            {/* <Card className="p-3 p-xl-5 my-3 my-xl-4"> */}
            <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    title= {<p className="mb-0 recuringTbl">Scheduling Payments</p>}
                    data={data}
                    columns={columns}
                    components={{icons:customToolBarIcons}}
                    options={{
                        selectableRows: "multiple",
                        selectableRowsHideCheckboxes: true,
                        selectableRowsOnClick: false,
                        // fixedHeader:true
                        responsive: 'standard',
                        customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => (
                            <CustomPagination
                              count={count}
                              page={page}
                              rowsPerPage={rowsPerPage}
                              onChangePage={changePage}
                              setCurrentPage={setCurrentPage}
                            />
                          )
                    }}
                />
            </ThemeProvider>
            {/* </Card> */}
            {/* </Col> */}
            {/* </Row> */}
        </>
    );
};