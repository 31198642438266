import { RootState } from "../../store";
import { IsStore } from "./indexStore";

export const getProfileDetails = ({ profileDetails }: IsStore) =>
  profileDetails.data;
export const getUpdateProfileDetails = ({ updateProfileDetails }: IsStore) =>
  updateProfileDetails.data;

export const getLoginInfo = ({ loginDetails }: IsStore) => loginDetails;

export const getQrInfo = ({ qrDetails }: IsStore) => qrDetails.data;

export const getForgotPassInfo = ({ forgotPassDetails }: IsStore) =>
  forgotPassDetails;

export const getResetPassInfo = ({ resetPassDetails }: IsStore) =>
  resetPassDetails;
export const getOtpInfo = ({ otpDetails }: IsStore) =>
  otpDetails;
export const getVerifyOtpInfo = ({ otpVerifyDetails }: IsStore) =>
  otpVerifyDetails.data;

export const sendSmsOtpSelec = (state: RootState) => state.sendSmsOTP
export const sendCallOtpSelec = (state: RootState) => state.sendCallOTP
export const socketSelec = (state: RootState) => state.socketReducer