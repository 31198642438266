import { createSlice } from "@reduxjs/toolkit";
import { Logout } from "../auth/operation";
import {
    GetUserCardsResponse,
    GetCardDetailsResponse,
    CardPaymentDetailsTokenResponse,
    PinControlTokenResponse,
    UpdateCardDataResponse,
    CreateCardResponse,
    ReauthenticatePasswordResponse,
    CardTopUpResponse,
    AllCardsNotificationsResponse,
    CreateVirtualCardResponse,
    AllCardsOrdersResponse,
    ActivateCardResponse,
    CreateCardPromptResponse
} from "./interface";
import {
    getAllCards,
    getCardDetails,
    initiateCardPaymentDetails,
    cardPinControlToken,
    updatePrepaidCard,
    createPhysicalCard,
    createVirtualCard,
    reauthenticatePassword,
    cardTopUp,
    getAllCardsNotifications,
    getAllCardsNotificationsPaginated,
    getAllCardOrders,
    activatePhysicalCard,
    showCreateCardPrompt,
} from "./operation";

const initialAllCardState = {
    data: {},
} as GetUserCardsResponse;

const initialCardState = {
    data: {},
} as GetCardDetailsResponse;

const initialCardPaymentDetailTokenState = {
    data: "",
} as CardPaymentDetailsTokenResponse;

const initialPinControlTokenState = {
    data: {},
} as PinControlTokenResponse;

const initialUpdatePrepaidCardState = {
    data: {},
} as UpdateCardDataResponse;

const initialCreatePhysicalCardState = {
    data: {},
} as CreateCardResponse;

const initialCreateVirtualCardState = {
    data: {},
} as CreateVirtualCardResponse;

const initialReauthPassState = {
    data: {},
} as ReauthenticatePasswordResponse;

const initialCardTopUpState = {
    data: {},
} as CardTopUpResponse;

const initialCardNotificationsState = {
    data: {},
} as AllCardsNotificationsResponse;

const initialCardOrdersState = {
    data: {},
} as AllCardsOrdersResponse;

const initialActivateCardState = {
    data: {},
} as ActivateCardResponse;

const initialCreatCardPromptResponse = {
    showPrompt: false,
    shownOnLogin: false,
} as CreateCardPromptResponse;

// get all cards created by user on B4B from or DB
const getAllCardSlice = createSlice({
    name: "allCards",
    initialState: initialAllCardState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllCards.pending, (state: any) => {
            state.status = "loading";
        });
        builder.addCase(getAllCards.fulfilled, (state: any, action) => {
            state.status = "succeeded";
            state.data = action.payload;
        });
        builder.addCase(getAllCards.rejected, (state: any) => {
            state.status = "failed";
        });
        builder.addCase(Logout.fulfilled, (state: any) => {
            state.data = {};
            state.status = "";
        });
    },
});

// get specific card details from B4B
const getCardDetailsSlice = createSlice({
    name: "cardDetails",
    initialState: initialCardState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCardDetails.pending, (state: any) => {
            state.status = "loading";
        });
        builder.addCase(getCardDetails.fulfilled, (state: any, action) => {
            state.status = "succeeded";
            state.data = action.payload;
        });
        builder.addCase(getCardDetails.rejected, (state: any) => {
            state.status = "failed";
        });
        builder.addCase(Logout.fulfilled, (state: any) => {
            state.data = {};
            state.status = "";
        });
    },
});

// get JWT Token generated from the BE for card payment details
const paymentDetailsTokenSlice = createSlice({
    name: "paymentDetailsToken",
    initialState: initialCardPaymentDetailTokenState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(initiateCardPaymentDetails.pending, (state: any) => {
            state.status = "loading";
        });
        builder.addCase(initiateCardPaymentDetails.fulfilled, (state: any, action) => {
            state.status = "succeeded";
            state.data = action.payload;
        });
        builder.addCase(initiateCardPaymentDetails.rejected, (state: any) => {
            state.status = "failed";
        });
        builder.addCase(Logout.fulfilled, (state: any) => {
            state.data = {};
            state.status = "";
        });
    },
});

// get physical card pin via our BE from B4B
const pinControlTokenSlice = createSlice({
    name: "pinControlToken",
    initialState: initialPinControlTokenState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(cardPinControlToken.pending, (state: any) => {
            state.status = "loading";
        });
        builder.addCase(cardPinControlToken.fulfilled, (state: any, action) => {
            state.status = "succeeded";
            state.data = action.payload;
        });
        builder.addCase(cardPinControlToken.rejected, (state: any) => {
            state.status = "failed";
        });
        builder.addCase(Logout.fulfilled, (state: any) => {
            state.data = {};
            state.status = "";
        });
    },
});

// update a physical cards details and usage limits and region blocking
const updatePrepaidCardSlice = createSlice({
    name: "updatePrepaidCard",
    initialState: initialUpdatePrepaidCardState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(updatePrepaidCard.pending, (state: any) => {
            state.status = "loading";
        });
        builder.addCase(updatePrepaidCard.fulfilled, (state: any, action) => {
            state.status = "succeeded";
            state.data = action.payload;
        });
        builder.addCase(updatePrepaidCard.rejected, (state: any) => {
            state.status = "failed";
        });
        builder.addCase(Logout.fulfilled, (state: any) => {
            state.data = {};
            state.status = "";
        });
    },
});

// create a physical card for customer
const createPhysicalCardSlice = createSlice({
    name: "createPhysicalCard",
    initialState: initialCreatePhysicalCardState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(createPhysicalCard.pending, (state: any) => {
            state.status = "loading";
        });
        builder.addCase(createPhysicalCard.fulfilled, (state: any, action) => {
            state.status = "succeeded";
            state.data = action.payload;
        });
        builder.addCase(createPhysicalCard.rejected, (state: any) => {
            state.status = "failed";
        });
        builder.addCase(Logout.fulfilled, (state: any) => {
            state.data = {};
            state.status = "";
        });
    },
});

// create a virtual card for customer
const createVirtualCardSlice = createSlice({
    name: "createVirtualCard",
    initialState: initialCreateVirtualCardState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(createVirtualCard.pending, (state: any) => {
            state.status = "loading";
        });
        builder.addCase(createVirtualCard.fulfilled, (state: any, action) => {
            state.status = "succeeded";
            state.data = action.payload;
        });
        builder.addCase(createVirtualCard.rejected, (state: any) => {
            state.status = "failed";
        });
        builder.addCase(Logout.fulfilled, (state: any) => {
            state.data = {};
            state.status = "";
        });
    },
});

// reauthenticate user password
const reauthPasswordSlice = createSlice({
    name: "reauthPassword",
    initialState: initialReauthPassState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(reauthenticatePassword.pending, (state: any) => {
            state.status = "loading";
        });
        builder.addCase(reauthenticatePassword.fulfilled, (state: any, action) => {
            state.status = "succeeded";
            state.data = action.payload;
        });
        builder.addCase(reauthenticatePassword.rejected, (state: any) => {
            state.status = "failed";
        });
        builder.addCase(Logout.fulfilled, (state: any) => {
            state.data = {};
            state.status = "";
        });
    },
});

// top up cards on B4B
const cardTopUpSlice = createSlice({
    name: "cardTopUp",
    initialState: initialCardTopUpState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(cardTopUp.pending, (state: any) => {
            state.status = "loading";
        });
        builder.addCase(cardTopUp.fulfilled, (state: any, action) => {
            state.status = "succeeded";
            state.data = action.payload;
        });
        builder.addCase(cardTopUp.rejected, (state: any) => {
            state.status = "failed";
        });
        builder.addCase(Logout.fulfilled, (state: any) => {
            state.data = {};
            state.status = "";
        });
    },
});

// fetch B4B cards notifications
const cardNotificationsSlice = createSlice({
    name: "cardNotifications",
    initialState: initialCardNotificationsState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllCardsNotifications.pending, (state: any) => {
            state.status = "loading";
        });
        builder.addCase(getAllCardsNotifications.fulfilled, (state: any, action) => {
            state.status = "succeeded";
            state.data = action.payload;
        });
        builder.addCase(getAllCardsNotifications.rejected, (state: any) => {
            state.status = "failed";
        });
        builder.addCase(Logout.fulfilled, (state: any) => {
            state.data = {};
            state.status = "";
        });
    },
});

// fetch B4B cards notifications
const cardNotificationsSlicePaginated = createSlice({
    name: "cardNotifications",
    initialState: initialCardNotificationsState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllCardsNotificationsPaginated.pending, (state: any) => {
            state.status = "loading";
        });
        builder.addCase(getAllCardsNotificationsPaginated.fulfilled, (state: any, action) => {
            state.status = "succeeded";
            state.data = action.payload;
        });
        builder.addCase(getAllCardsNotificationsPaginated.rejected, (state: any) => {
            state.status = "failed";
        });
        builder.addCase(Logout.fulfilled, (state: any) => {
            state.data = {};
            state.status = "";
        });
    },
});

// fetch physical card orders on B4B from our DB
const cardOrdersSlice = createSlice({
    name: "cardOrders",
    initialState: initialCardOrdersState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllCardOrders.pending, (state: any) => {
            state.status = "loading";
        });
        builder.addCase(getAllCardOrders.fulfilled, (state: any, action) => {
            state.status = "succeeded";
            state.data = action.payload;
        });
        builder.addCase(getAllCardOrders.rejected, (state: any) => {
            state.status = "failed";
        });
        builder.addCase(Logout.fulfilled, (state: any) => {
            state.data = {};
            state.status = "";
        });
    },
});

// activate physical card 
const activateCardSlice = createSlice({
    name: "activateCard",
    initialState: initialActivateCardState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(activatePhysicalCard.pending, (state: any) => {
            state.status = "loading";
        });
        builder.addCase(activatePhysicalCard.fulfilled, (state: any, action) => {
            state.status = "succeeded";
            state.data = action.payload;
        });
        builder.addCase(activatePhysicalCard.rejected, (state: any) => {
            state.status = "failed";
        });
        builder.addCase(Logout.fulfilled, (state: any) => {
            state.data = {};
            state.status = "";
        });
    },
});

// create card prompt
const createCardPromptSlice = createSlice({
    name: "createCardPrompt",
    initialState: initialCreatCardPromptResponse,
    reducers: {
        shownToUser: (state) => {
            state.shownOnLogin = true;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(showCreateCardPrompt.pending, (state: any) => {
            state.status = "loading";
        });
        builder.addCase(showCreateCardPrompt.fulfilled, (state: any, action) => {
            state.status = "succeeded";
            state.showPrompt = action.payload.showPrompt;
        });
        builder.addCase(showCreateCardPrompt.rejected, (state: any) => {
            state.status = "failed";
        });
        builder.addCase(Logout.fulfilled, (state: any) => {
            state.showPrompt = false;
            state.shownOnLogin = false;
            state.status = "";
        });
    },
});

export const createCardPromptActions = createCardPromptSlice.actions;

export default {
    getAllCardSlice,
    getCardDetailsSlice,
    paymentDetailsTokenSlice,
    pinControlTokenSlice,
    updatePrepaidCardSlice,
    createPhysicalCardSlice,
    createVirtualCardSlice,
    reauthPasswordSlice,
    cardTopUpSlice,
    cardNotificationsSlice,
    cardNotificationsSlicePaginated,
    cardOrdersSlice,
    activateCardSlice,
    createCardPromptSlice,
};

