import { persistReducer } from "redux-persist";
import {
  forgotPassDetailsConfig,
  loginDetailsConfig,
  otpVerifyDetailsConfig,
  profileDetailsConfig,
  qrDetailsConfig,
  resetPassDetailsConfig,
  selectedMerchantconfig,
} from "../toolkit/auth/persistStore";
import ProfileReducer from "../toolkit/auth/reducer";

import { recentOpConfig } from "../toolkit/recentOperation/persistStore";
import RecentOperationReducer from "../toolkit/recentOperation/reducer";

import { exchangeRateConfig } from "../toolkit/exchangeRates/persistStore";
import ExchangeRateReducer from "../toolkit/exchangeRates/reducer";

import { expenseDetailsConfig } from "../toolkit/expenseCard/persistStore";
import ExpenseReducer from "../toolkit/expenseCard/reducer";

import { beneficiaryConfig } from "../toolkit/beneficiaries/persistStore";
import BeneficiaryReducer from "../toolkit/beneficiaries/reducer";

import { sendFundsConfig } from "../toolkit/sendFunds/persistStore";
import SendFundsReducer from "../toolkit/sendFunds/reducer";

import { convertConfig, convertPayConfig } from "../toolkit/convert/persistStore";
import ConvertReducer from "../toolkit/convert/reducer";

import { getAllAccountsConfig } from "../toolkit/getAllUserAccount/persistStore";
import GetAllAccountsReducer from "../toolkit/getAllUserAccount/reducer";

import { accountBalanceConfig } from "../toolkit/accountBalances/persistStore";
import BalanceReducer from "../toolkit/accountBalances/reducer";

// import { qrVerifyPaymentsConfig, otpVerifyPaymentsConfig } from "../toolkit/verifyPayment/persistStore";
// import { invoiceConfig } from "../toolkit/verifyPayment/persistStore";
import VerficationPaymentReducer from "../toolkit/verifyPayment/reducer";

import { getAllCardConfig, getAllCardOrdersConfig } from "../toolkit/cards/persistStore";
import CardReducers from "../toolkit/cards/reducer";
import { cardPaymentsConfig } from "../toolkit/cardPayments/persistStore";
import CardPaymentsReducer from "../toolkit/cardPayments/reducer";
import infoMessageReducer from "../toolkit/infoMessage/reducer"

const reducers = {
  loginDetails: persistReducer(loginDetailsConfig, ProfileReducer.loginDetailsSlice.reducer),
  forgotPassDetails: persistReducer(forgotPassDetailsConfig, ProfileReducer.forgotPassDetailsSlice.reducer),
  resetPassDetails: persistReducer(resetPassDetailsConfig, ProfileReducer.resetPassDetailsSlice.reducer),
  profileDetails: persistReducer(profileDetailsConfig, ProfileReducer.profileDetailsSlice.reducer),
  qrDetails: persistReducer(qrDetailsConfig, ProfileReducer.qrDetailsSlice.reducer),
  selectedMerchant: persistReducer(selectedMerchantconfig, ProfileReducer.selectedMerchantSlice.reducer),
  // selectedMerchant: ProfileReducer.selectedMerchantSlice.reducer,
  otpVerifyDetails: persistReducer(otpVerifyDetailsConfig, ProfileReducer.otpVerifyDetailsSlice.reducer),
  sendSmsOTP: ProfileReducer.otpDetailsSlice.reducer,
  sendCallOTP: ProfileReducer.sendOtpCallSlice.reducer,
  socketReducer: ProfileReducer.socketSlice.reducer,
  sendFundsDetails: persistReducer(sendFundsConfig, SendFundsReducer),
  exchangeRates: persistReducer(exchangeRateConfig, ExchangeRateReducer),
  recentOperation: persistReducer(recentOpConfig, RecentOperationReducer),
  expenseDetails: persistReducer(expenseDetailsConfig, ExpenseReducer),
  beneficiary: persistReducer(beneficiaryConfig, BeneficiaryReducer),
  convert: persistReducer(convertConfig, ConvertReducer.convertDetailsSlice),
  convertPay: persistReducer(convertPayConfig, ConvertReducer.convertDetailsPaySlice),
  getAllAccounts: persistReducer(getAllAccountsConfig, GetAllAccountsReducer),
  getAllAccountBalance: persistReducer(accountBalanceConfig, BalanceReducer),
  paymentVerfication: VerficationPaymentReducer.qrPaymentSlice.reducer,
  otppaymentVerfication: VerficationPaymentReducer.otpPaymentSlice.reducer,
  // invoice: persistReducer(invoiceConfig, VerficationPaymentReducer.invoiceSlice.reducer),
  invoice: VerficationPaymentReducer.invoiceSlice.reducer,
  cardPayments: persistReducer(cardPaymentsConfig, CardPaymentsReducer.cardPaymentsSlice.reducer),
  // B4B prepaid cards reducers: START
  getAllCards: persistReducer(getAllCardConfig, CardReducers.getAllCardSlice.reducer),
  getCardDetails: CardReducers.getCardDetailsSlice.reducer,
  paymentDetailsToken: CardReducers.paymentDetailsTokenSlice.reducer,
  pinControlToken: CardReducers.pinControlTokenSlice.reducer,
  updatePrepaidCard: CardReducers.updatePrepaidCardSlice.reducer,
  createPhysicalCard: CardReducers.createPhysicalCardSlice.reducer,
  createVirtualCard: CardReducers.createVirtualCardSlice.reducer,
  reauthenticatePassword: CardReducers.reauthPasswordSlice.reducer,
  cardTopUp: CardReducers.cardTopUpSlice.reducer,
  cardNotifications: CardReducers.cardNotificationsSlice.reducer,
  cardNotificationsPaginated: CardReducers.cardNotificationsSlicePaginated.reducer,
  cardOrders: persistReducer(getAllCardOrdersConfig, CardReducers.cardOrdersSlice.reducer),
  activateCard: CardReducers.activateCardSlice.reducer,
  createCardPrompt: CardReducers.createCardPromptSlice.reducer,
  // B4B prepaid cards reducers: END
  infoMessage: infoMessageReducer,
};

export default reducers;