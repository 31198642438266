import React, { useEffect, useState } from "react";
import { Col, Form, Modal, ModalFooter } from "react-bootstrap";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CurrencyFlag from "react-currency-flags";
import { useTranslation } from "react-i18next";
import "../../index.css"
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { FormattedCard, SelectedBusinessAccount, LoadCardData } from "../../interface";
import BasicButton from "../../../../components/shared/Buttons/Basic";
import InputTypeText from "../../../../components/shared/Input/Basic/InputTypeText";
import AutoTopUpIcon from "../../../../assets/images/cardAssets/auto-topup.svg";
import Switch from "@mui/material/Switch";

const AddMoneyModal = (props: {
  showAddMoneyModal: boolean,
  loadCardData: LoadCardData,
  setLoadCardData: React.Dispatch<React.SetStateAction<LoadCardData>>,
  setShowAddMoneySummary: React.Dispatch<React.SetStateAction<boolean>>,
  setShowAddMoney: React.Dispatch<React.SetStateAction<boolean>>,
  handleClose: () => void,
  selectedBusinessAcc: SelectedBusinessAccount;
  setSelectedBusinessAcc: React.Dispatch<React.SetStateAction<SelectedBusinessAccount>>;
  selectedCard: FormattedCard;
}) => {
  const { showAddMoneyModal, loadCardData, setLoadCardData, setShowAddMoney, setShowAddMoneySummary, handleClose, setSelectedBusinessAcc, selectedBusinessAcc, selectedCard } = props;
  const accountSelector: any = useSelector(
    (state: RootState) => state.getAllAccounts.data
  );
  const [isAutoTopUpOn, setIsAutoTopUpOn] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "amount") {
      // Remove non-numeric and non-decimal characters
      const numericValue = value.replace(/[^\d.]/g, '');
      // Remove leading zeros except for the first character if it's a decimal point
      const cleanedValue = numericValue.replace(/^0+(?=\d)|\.0*$/g, '');
      // Ensure there is at most one decimal point
      const decimalCount = cleanedValue.split('.').length - 1;
      let limitedValue = cleanedValue;
      if (decimalCount > 1) {
        // If more than one decimal point, keep only the first one
        limitedValue = cleanedValue.replace(/\.([^.]*)$/, '$1');
      }
      // Limit the length of the number to 10 digits
      const limitedLengthValue = limitedValue.slice(0, 10);
      // Convert the value to a number
      const numVal = Number(limitedLengthValue);
      setLoadCardData({ ...loadCardData, [name]: numVal })
    } else {
      setLoadCardData({ ...loadCardData, [name]: value })
    }
  }

  const handleAutoTopUp = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAutoTopUpOn(event.target.checked);
  };

  const handleSubmit = (event: any) => {
    setShowAddMoneySummary(true);
    setShowAddMoney(false);
  };

  useEffect(() => {
    if (accountSelector?.data?.data) {
      const matchingAccount = accountSelector.data.data.find((acc: any) => acc.accB4BUUID === selectedCard?.accB4BUUID);
      if (matchingAccount) {
        setSelectedBusinessAcc(matchingAccount)
      } else {
        setSelectedBusinessAcc({})
      }
    }
  }, [accountSelector]);

  return (
    <Modal
      show={showAddMoneyModal}
      onHide={() => {
        setLoadCardData({ amount: 0, description: "" });
        return handleClose();
      }}
      centered
      style={{ marginTop: '8px' }}
    >
      <Modal.Header style={{ borderBottom: "0px" }}>
        <div>
          <Modal.Title>Add Money</Modal.Title>
        </div>
        <IconButton
          style={{ backgroundColor: '#006ff4', color: 'white', width: '22px', height: '22px' }}
          onClick={() => {
            setLoadCardData({ amount: 0, description: "" });
            return handleClose();
          }}
        >
          <CloseIcon style={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Modal.Header  >
      <Modal.Body style={{ borderTop: "0px" }}>
        <p style={{ margin: 0, fontWeight: "600", marginBottom: "10px" }}>Business Account:</p>
        <Col className="col-12 p-2"
          style={{
            border: '1px solid #D7F0FF',
            borderRadius: "10px",
          }}>
          {selectedBusinessAcc.accountName ?
            <p style={{ margin: 0 }} className="currencyFlag">
              {selectedBusinessAcc.accountName}
            </p>
            :
            <p style={{ margin: 0, color: "gray" }}>
              --- No Corresponding Business Accounts ---
            </p>
          }
        </Col>

        <p style={{ margin: 0, fontWeight: "600", marginTop: "20px" }}>Amount to Top Up</p>
        <div className="addMoney-AmountToTopUp mt-2">
          <Col className="col-3"
            style={{
              border: '1px solid #D7F0FF',
              borderRadius: "10px",
            }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                height: '100%'
              }}
            >
              <CurrencyFlag currency={selectedCard.currency} />
              <p style={{ margin: 0 }} className="currencyFlag">{selectedCard.currency}</p>
            </div>
          </Col>

          <Col className="col-9">
            <div className="addMoney-AmountToTopUp-AmountInput">
              <InputTypeText
                compulsoryValue
                type="number"
                inputContainerMargins="0"
                placeholder="Amount"
                name="amount"
                value={loadCardData.amount}
                onChange={handleChange}
              />
            </div>
          </Col>
        </div>

        <p style={{ margin: 0, fontWeight: "600", marginTop: "20px" }}>Description</p>
        <InputTypeText
          type="text"
          inputContainerMargins="0"
          placeholder="Will appear in statement"
          value={loadCardData.description}
          name="description"
          onChange={handleChange}
          maxLength={250}
        />

        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "14px 14px",
            boxShadow: "0px 0px 6px 2px #001B3A0F",
            marginTop: "35px",
            borderRadius: "10px",
            justifyContent: "space-between"
          }}
        >
          <Col className="mainBox-icon col-2 col-md-3 col-lg-3">
            <img src={AutoTopUpIcon} alt="Auto Top-Up" style={{width: "24px" , height: "24px"}} />
          </Col>
          <Col
            className="col-7 col-md-8 col-lg-8"
            style={{ fontWeight: 600, fontSize: "14px", color: "#231F20" }}
          >
            Auto Top-Up
          </Col>
          <Col className="col-2">
            <Switch
              checked={isAutoTopUpOn}
              onChange={handleAutoTopUp}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Col>
        </div>
        {isAutoTopUpOn && 
        <div className="mt-3">
          <p style={{fontWeight: 600}}>Minimum Balance for Auto-Top-Up</p>
          <div className="addMoney-AmountToTopUp mt-2">
          <Col
            className="col-3"
            style={{
              border: "1px solid #D7F0FF",
              borderRadius: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CurrencyFlag currency={selectedCard.currency} />
              <p style={{ margin: 0 }} className="currencyFlag">
                {selectedCard.currency}
              </p>
            </div>
          </Col>

          <Col className="col-9">
            <div className="addMoney-AmountToTopUp-AmountInput">
              <InputTypeText
                compulsoryValue
                type="number"
                inputContainerMargins="0"
                placeholder="Amount"
                name="amount"
              />
            </div>
          </Col>
        </div>
        </div>
        }
      </Modal.Body>

      <ModalFooter style={{ borderTop: "0px" }}>
        <BasicButton
          type="button"
          onClick={handleSubmit}
          disabled={selectedBusinessAcc.accountName && (loadCardData.amount !== 0) ? false : true}
          text="Add Money"
        />
      </ModalFooter>
    </Modal >
  )
};

export default AddMoneyModal;