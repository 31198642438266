
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, ModalFooter, Spinner } from "react-bootstrap";
import { AppDispatch, useAppSelector } from "../../../../redux/store";
import StrongCustomerAuthentication from "../../../../components/shared/StrongCustomerAuthentication";
import { Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { resetStatus } from "../../../../redux/actions";
import BasicButton from "../../../../components/shared/Buttons/Basic";
import moment from "moment";
import { socketSelec } from "../../../../redux/toolkit/auth/selector";
import { socketActions } from "../../../../redux/toolkit/auth/reducer";

const ThreeDSAuthModal = ({ data, closeModal }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [authCompleted, setAuthCompleted] = useState<boolean>(false);

  const socketSelector: any = useSelector(socketSelec);

  const onAccept = () => {
    if (authCompleted) {
      dispatch(socketActions.emitWebhookResponse({
        company_uuid: data.company_uuid,
        uuid: data.uuid,
        card_code: data.card_code,
        status: "accept",
      }));
    }
  }

  const onReject = () => {
    dispatch(socketActions.emitWebhookResponse({
      company_uuid: data.company_uuid,
      uuid: data.uuid,
      card_code: data.card_code,
      status: "decline",
    }));
  }

  function maskCardNumber(cardNumber: string) {
    // Assuming cardNumber is a string with the last 4 digits
    const masked = '*'.repeat(12) + cardNumber;
    return masked.replace(/(.{4})/g, '$1 ');
  }

  function formattedExpiry(expiryStr: string) {
    const momentExpiry = moment(expiryStr);
    return momentExpiry.format("DD MMM YYYY, hh:mm A");
  }

  return (
    <Modal show centered onHide={() => {
      dispatch(resetStatus());
      return dispatch(socketActions.clearCallbackResponse());
    }}>
      <Modal.Header style={{ display: 'flex', alignItems: 'center', borderBottom: 0 }}>
        <div>
          <Modal.Title>3DS Authentication Required</Modal.Title>
        </div>
      </Modal.Header>

      <Modal.Body className="pt-2 pb-2">
        {socketSelector.confirmation ?
          <p style={{ marginTop: "5px", fontSize: "18px", marginBottom: "10px", fontWeight: "600" }}>{socketSelector.callbackResponse.message}</p>
          :
          <>
            <p style={{ fontSize: "14px", margin: 0, color: "#ADB6C0" }}>Merchant Name</p>
            <p style={{ marginTop: "5px", fontSize: "18px", marginBottom: "10px", fontWeight: "600" }}>{data.merchant}</p>

            <p style={{ fontSize: "14px", margin: 0, color: "#ADB6C0" }}>Amount</p>
            <p style={{ marginTop: "5px", fontSize: "16px", marginBottom: "10px", fontWeight: "600" }}>{data.amount}</p>

            <p style={{ fontSize: "14px", margin: 0, color: "#ADB6C0" }}>Last 4 card digits</p>
            <p style={{ marginTop: "5px", fontSize: "16px", marginBottom: "10px", fontWeight: "600" }}>{maskCardNumber(data.lastDigits)}</p>

            <p style={{ fontSize: "14px", margin: 0, color: "#ADB6C0" }}>Valid until</p>
            <p style={{ marginTop: "5px", fontSize: "16px", marginBottom: "10px", fontWeight: "600" }}>{formattedExpiry(data.expiry)}</p>

            <p style={{ fontSize: "14px", margin: 0, color: "#ADB6C0" }}>Authentication required before accepting transaction</p>
            <StrongCustomerAuthentication setAuthCompleted={setAuthCompleted} showImage={false} />
          </>
        }
      </Modal.Body>

      <ModalFooter style={{ borderTop: "0px", paddingTop: 0 }}>
        {socketSelector.loader ?
          <Col className="col-12 d-flex justify-content-center">
            <Spinner animation={"border"} />
          </Col> :
          socketSelector.confirmation ?
            <Col className="col-12 d-flex justify-content-center">
              <BasicButton
                type="button"
                text="Okay"
                style={{ width: '95%' }}
                onClick={() => {
                  dispatch(resetStatus());
                  dispatch(socketActions.clearCallbackResponse());
                  return closeModal()
                }}
              />
            </Col> :
            <div style={{ display: 'flex', width: '100%' }}>
              <Col className="col-6 d-flex justify-content-center">
                <Tooltip title={!authCompleted
                  ? `Complete authentication above!`
                  : ""}
                  placement="left"
                >
                  <div style={{ display: 'flex', width: '100%' }}>
                    <BasicButton
                      type="submit"
                      text="Accept"
                      style={{ width: '95%' }}
                      onClick={onAccept}
                      disabled={!authCompleted}
                    />
                  </div>
                </Tooltip>
              </Col>

              <Col className="col-6 d-flex justify-content-center">
                <BasicButton
                  type="submit"
                  style={{
                    width: '95%',
                    backgroundColor: "white",
                    color: "#006ff4",
                    border: "1px solid #006ff4",
                  }}
                  onClick={onReject}
                  text="Reject"
                />
              </Col>
            </div>
        }
      </ModalFooter>
    </Modal>
  )
};

type Props = {
  data: any,
  closeModal: () => void
}

export default ThreeDSAuthModal;