
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { B4BCardHolder, B4BCardHolderAddress } from "../../interface";
import ApplyforCardForm from "./ApplyforCardForm";
import * as iso from "iso-3166-1";

const ApplyForCardModal = (props: {
  applyCard: boolean,
  setApplyCard: any
  handleClose: any,

}) => {
  const { applyCard, setApplyCard, handleClose } = props;

  // fill relevant user data from local storage when user wants a new card
  const [cardHolder, setCardHolder] = useState<B4BCardHolder>({
    embossedName: "",
    title: "",
    first_name: localStorage.getItem("firstName") || "",
    last_name: localStorage.getItem("lastName") || "",
    gender: "",
    dateOfBirth: "",
    email: localStorage.getItem("userEmail") || "",
    locale: "EN",
    mobileNo: localStorage.getItem("userPhone") || "",
    telephoneNo: ""
  });
  const [cardHolderAddress, setCardHolderAddress] = useState<B4BCardHolderAddress>({
    line_1: "",
    city: localStorage.getItem("city") || "",
    post_code: localStorage.getItem("postalCode") || "",
    country_code: localStorage.getItem("country") !== null ? iso.whereCountry(localStorage.getItem("country")!)!.numeric : "",
  });

  return (
    <Modal show={applyCard} onHide={handleClose}>
      <Modal.Header style={{ display: 'flex', alignItems: 'center', borderBottom: 0 }}>
        <div>
          <Modal.Title>Apply for Card</Modal.Title>
          <p style={{ marginBottom: 0 }}>
            Verify your information below
          </p>
        </div>
        <IconButton
          style={{ backgroundColor: '#006ff4', color: 'white', width: '22px', height: '22px' }}
          onClick={handleClose}
        >
          <CloseIcon style={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Modal.Header>
      <Modal.Body className="pt-2 pb-2">
        <ApplyforCardForm
          cardHolder={cardHolder}
          setCardHolder={setCardHolder}
          cardHolderAddress={cardHolderAddress}
          setCardHolderAddress={setCardHolderAddress}
          setApplyCard={setApplyCard}
        />
      </Modal.Body>
    </Modal>
  )
};

export default ApplyForCardModal;