import React, { useState } from "react";
import { Form, Modal, ModalFooter } from "react-bootstrap";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { UpdateCardDataPayload } from "../../../../redux/toolkit/cards/interface";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { getAllCards, updatePrepaidCard } from "../../../../redux/toolkit/cards/operation";
import cardSelectors from "../../../../redux/toolkit/cards/selector";
import Swal from "sweetalert2";
import { FormattedCard } from "../../interface";
import BasicButton from "../../../../components/shared/Buttons/Basic";
import InputTypeText from "../../../../components/shared/Input/Basic/InputTypeText";


const CardLabelModal = (props: {
  showCardLabel: boolean,
  selectedCardId: string,
  selectedCard: FormattedCard,
  handleClose: () => void,
}) => {
  const { showCardLabel, selectedCardId, selectedCard, handleClose } = props;
  const dispatch = useDispatch<AppDispatch>();
  const [label, setLabel] = useState<string | undefined>(selectedCard.cardLabel)

  const updateCardSelector = useSelector(cardSelectors.updateCardSelect);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLabel(event.target.value);
  }

  const handleClick = () => {
    const labelPayload: UpdateCardDataPayload = {
      userId: localStorage.getItem("userId")!,
      card_code: selectedCardId,
      //company UUID (B4B account UUID) for the cards
      // uuid: "bed202f2-5b7c-484c-af69-7b374c0c76d5",
      uuid: selectedCard.accB4BUUID || "",
      cardLabel: label,
    }
    dispatch(updatePrepaidCard(labelPayload))
      .unwrap()
      .then((resp) => {
        dispatch(getAllCards({ userId: localStorage.getItem('userId')! }))
        Swal.fire({
          icon: "success",
          title: "Success",
          confirmButtonColor: "#86B6EF",
          text: "Your card label has been updated!",
        }).then(() => { handleClose(); });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Error",
          confirmButtonColor: "#86B6EF",
          text: "Error updating card label, please try again!",
        }).then(() => { handleClose(); });
      })
  };

  return (
    <Modal show={showCardLabel} onHide={handleClose} centered >
      <Modal.Header style={{ borderBottom: "0px" }}>
        <div>
          <Modal.Title>Card Label</Modal.Title>
        </div>
        <IconButton
          style={{ backgroundColor: '#006ff4', color: 'white', width: '22px', height: '22px' }}
          onClick={handleClose}
        >
          <CloseIcon style={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Modal.Header>

      <Modal.Body className="pt-2 pb-2">
        <p style={{ fontWeight: '600', margin: 1 }}>
          Your card label is
        </p>
        <InputTypeText
          type="text"
          compulsoryValue
          placeholder={"Enter your card label"}
          value={label}
          onChange={handleInputChange}
          inputContainerMargins="0"
        />
      </Modal.Body>
      <ModalFooter style={{ borderTop: "0px" }}>
        <BasicButton
          type="button"
          onClick={handleClick}
          isLoading={updateCardSelector.status === "loading"}
          text="Update label"
        />
      </ModalFooter>
    </Modal>
  )
};

export default CardLabelModal;