import React, { useEffect, useState } from "react";
import InputComponent from "../../components/shared/Input/Index";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { AppDispatch } from "../../redux/store";
import { UserRegistration } from "../../redux/toolkit/auth/operation";
import { useSelector } from "react-redux";
import { getProfileDetails } from "../../redux/toolkit/auth/selector";
import { useNavigate } from "react-router-dom";
import { errorMsgs, regexStrConst, LOGO_URL } from "../../constants/stringConstants";
import { Button, Spinner, Form } from "react-bootstrap";
import LanguageDropdown from "../../components/shared/TranslationDropdown";
import "./index.css";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/bootstrap.css'
import { BsCheckLg } from "react-icons/bs";
import * as iso from "iso-3166-1";
import { ErrorImage, OMWLogoWhite } from "../../constants/Images";
import InputTypeText from "../../components/shared/Input/Basic/InputTypeText";
import AutoCompleteInput from "../../components/shared/Input/AutoCompleteInput";
import InputTypeSecure from "../../components/shared/Input/InputTypeSecure";
import BasicButton from "../../components/shared/Buttons/Basic";
import { getCountry } from "../../constants/locationService";
import cities from 'cities.json';

type City = {
  name: string;
  country: string;
  lat?:string
  lng?:string
}
const cityData: City[] = cities as City[];

const Registration = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState<any>("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [ConfirmpassError, setConfirmPassError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  const [companyAddressError, setCompanyAddressError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [agree, setAgree] = useState(false);
  const [loading, setLoading] = useState(false);

  const [city, setCity] = useState("");
  const [cityErrorMsg, setCityErrorMsg] = useState("");

  const [country, setCountry] = useState("");
  const [countryErrorMsg, setCountryErrorMsg] = useState("");

  const [postalCode, setPostalCode] = useState("");
  const [postalCodeErrorMsg, setPostalCodeErrorMsg] = useState("");

  const [companyRegNumber, setCompanyRegNumber] = useState("");
  const [companyRegNumberErrorMsg, setCompanyRegNumberErrorMsg] = useState("");
  const [companyLogoError, setCompanyLogoError] = useState("");
  const [CompanyLogoBase64, setCompanyLogoBase64] = useState("");

  const [companyLogoFile, setCompanyLogoFile] = useState("");
  const countries = iso.all();

  const [isShowCreatePass, setIsShowCreatePass] = useState(true);
  const [isShowConfirmPass, setIsShowConfirmPass] = useState(true);
  const [citiesByCountry, setCitiesByCountry] = useState<City[]>([]);


  const registerationDetails: any = useSelector(getProfileDetails);
  const dispatch = useDispatch<AppDispatch>();

  const { t } = useTranslation();

  useEffect(() => {
    if (registerationDetails.data) {
      if (Object.keys(registerationDetails.data).length > 0) {
        navigate("/");
      }
    }
    getCurrentUserCountry();
  }, [registerationDetails]);

  const onChangeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;
    if (item.length < 3) {
      setFirstNameError(errorMsgs.emptyName);
    } else {
      setFirstNameError("");
    }
    setFirstName(item);
  };

  const onChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;
    if (item.length < 3) {
      setLastNameError(errorMsgs.emptyName);
    } else {
      setLastNameError("");
    }
    setLastName(item);
  };

  const onChangeCompanyName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;
    if (item.length < 3) {
      setCompanyNameError(errorMsgs.emptyName);
    } else {
      setCompanyNameError("");
    }
    setCompanyName(item);
  };

  const onChangeCompanyAddress = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const item = event.target.value;
    if (item.length < 3) {
      setCompanyAddressError(errorMsgs.emptyAddress);
    } else {
      setCompanyAddressError("");
    }
    setCompanyAddress(item);
  };

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;
    if (regexStrConst.emailRegex.test(item)) {
      setEmailError("");
    } else {
      setEmailError(errorMsgs.emptyEmail);
    }
    setEmail(item);
  };

  const onChangePhoneNo = (value: any, country: any) => {
    // const item = event.target.value;
    if (regexStrConst.phoneNumberRegex.test(value)) {
      setPhoneNumberError("");
    } else {
      setPhoneNumberError(errorMsgs.emptyPhone);
    }
    // setCountryCode(country?.countryCode)
    setPhoneNumber(value);
  };

  const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;

    if (!regexStrConst.passwordRegex.test(item)) {
      setPasswordError(errorMsgs.emptyPassword);
    } else {
      setPasswordError('');
    }
    setPassword(item);
  };

  const onChangeConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;
    if (!regexStrConst.passwordRegex.test(item)) {
      setConfirmPassError(errorMsgs.emptyPassword);
    }
    else if (item !== password) {
      setConfirmPassError(errorMsgs.confirmPassword);
    }
    else {
      setConfirmPassError("");
    }
    setConfirmPass(item);
  };

  // const onChangeCity = (event: React.ChangeEvent<HTMLInputElement>) => {
  const onChangeCity = (event: string) => {
    const item = event;
    setCityErrorMsg('')
    setCity(item);
  };

  const onChangeCountry = (event: any) => {
    const item = event;
    setCountry(item);
    setCity('')
    const countCd = countries.find((c)=>c.country===item)?.alpha2;
    setCountryCode(countCd?.toLocaleLowerCase())
    getCitiesByCountry(countCd || '')
    if (item) {
      setCountryErrorMsg('')
    }
  };

  const onChangePostalCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;
    // if (item) {
    setPostalCode(item);
    setPostalCodeErrorMsg('')
    // }
  };

  const onChangeCompanyRegNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;
    setCompanyRegNumber(item);
    setCompanyRegNumberErrorMsg('')
  };

  const handleLogoUpload = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      if (file.size > 1000000) {
        Swal.fire({
          icon: "info",
          title: "Oopss",
          text: "Logo size should be less than 1MB",
        })
      } else {
        reader.onloadend = () => {
          if (typeof reader.result === 'string') {
            setCompanyLogoBase64(reader.result);
            setCompanyLogoError("");
          } else {
            console.error('Unexpected result type:', typeof reader.result);
          }
        };
      }

      reader.readAsDataURL(file);
      setCompanyLogoFile(file)
    } else {
      setCompanyLogoError(errorMsgs.emptyLogo);
    }
  };




  const validate = () => {
    if (firstName === "") setFirstNameError(errorMsgs.emptyName);
    if (lastName === "") setLastNameError(errorMsgs.emptyName);
    if (companyName === "") setCompanyNameError(errorMsgs.emptyName);
    if (companyAddress === "") setCompanyAddressError(errorMsgs.emptyAddress);
    if (email === "") setEmailError(errorMsgs.emptyEmail);
    if (phoneNumber === "") setPhoneNumberError(errorMsgs.emptyPhone);
    if (password === "") setPasswordError(errorMsgs.emptyPassword);
    if (confirmPass === "") setConfirmPassError(errorMsgs.emptyPassword);
    // if (!regexStrConst.passwordRegex.test(password) ) setPasswordError(errorMsgs.emptyPasswordReg);
    if (city === "") setCityErrorMsg(errorMsgs.emptyCity)
    if (country === "") setCountryErrorMsg(errorMsgs.emptyCountry)
    if (postalCode === "") setPostalCodeErrorMsg(errorMsgs.emptyPostCode)
    if (companyRegNumber === "") setCompanyRegNumberErrorMsg(errorMsgs.emptyCompanyRegNo)
    // if (!CompanyLogoBase64) {
    //   setCompanyLogoError(errorMsgs.emptyLogo);
    // } else {
    //   setCompanyLogoError("");
    // }

    return email === "" ||
      password === "" ||
      firstName === "" ||
      lastName === "" ||
      companyName === "" ||
      companyAddress === "" ||
      phoneNumber === "" ||
      confirmPass === "" ||
      city === "" ||
      country === "" ||
      postalCode === "" ||
      companyRegNumber === "" ||
      // !CompanyLogoBase64 ||

      firstNameError ||
      lastNameError ||
      companyNameError ||
      companyAddressError ||
      emailError ||
      phoneNumberError ||
      passwordError ||
      ConfirmpassError ||
      cityErrorMsg ||
      countryErrorMsg ||
      postalCodeErrorMsg ||
      companyRegNumberErrorMsg ||
      companyLogoError
      ? false
      : true;
  };

  const handleButtonClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (validate()) {
      if (password === confirmPass) {
        setLoading(true);
        const payload = {
          companyName: companyName || "",
          companyAddress: companyAddress || "",
          email: email,
          phoneNo: "+" + phoneNumber,
          password: password,
          firstName: firstName,
          lastName: lastName,
          city: city,
          country: country,
          postalCode: postalCode,
          countryCode: countryCode,
          companyRegNumber: companyRegNumber,
          // companyLogoBase64: CompanyLogoBase64,
          companyLogoBase64: companyLogoFile,
        };
        dispatch(UserRegistration(payload)).then(() => setLoading(false));
      } else {
        Swal.fire({
          // icon: "error",
          imageUrl: ErrorImage,
          title: "Oopss",
          text: "Password should match",
        });
      }
    }
  };
  const checkboxHandler = () => {
    setAgree(!agree);
  };

  const isValidEmailOnBlur = (e: string) => {
    if (!e || !regexStrConst.emailRegex.test(e)) {
      setEmailError(errorMsgs.emptyEmail)
    }
    else {
      setEmailError('')
    }
  }
  const isValidPassOnBlur = (e: string) => {
    if (!e || !regexStrConst.passwordRegex.test(e)) {
      setPasswordError(errorMsgs.emptyPassword)
    }
    else {
      setPasswordError('')
    }
  }

  const isValidConfirmPassOnBlur = (e: string) => {
    if (!e || !regexStrConst.passwordRegex.test(e)) {
      setConfirmPassError(errorMsgs.emptyPassword)
    }
    else if (password && e !== password) {
      setConfirmPassError(errorMsgs.confirmPassword)
    }
    else {
      setConfirmPassError('')
    }
  }

  const getCurrentUserCountry =()=>{
    const geoCountry = getCountry() 
    onChangeCountry(geoCountry);
  }

  const getCitiesByCountry = (code: string) => {
    if(code){
      let  tempCity = cityData.filter(city => city.country === code);
      const unique = tempCity.filter(
        (obj, index) =>tempCity.findIndex((item) => item.name === obj.name) === index
      );

      tempCity = unique.map(t => ({...t, label: t.name}))
      setCitiesByCountry(tempCity)
    }
    
};


  return (
    <div className="Registration m-2">
      <div className="onBoardingImage onBoardingBGImg">
        {/* <div className="logo-box">
          <div className="logo-wrapper">
            <img
              className="logo"
              style={{ width: 210, height: 80, filter: "drop-shadow(0 0 0.4px)" }}
              src={LOGO_URL}
              alt="One Money Way"
            ></img>
          </div>
        </div> */}
        <img className="omwLogo registrationPageIcon" src={OMWLogoWhite} />

        {/* <div className="left-title-box">
          <h2 className="left-title">{t("login.title.payment")}</h2>
          <h2 className="left-title-second">{t("login.title.platform")}</h2>
          <h2 className="left-title-third">{t("login.title.SMEs")}</h2>
        </div> */}
        {/* <div className="welcome-box">
          <p className="welcome">{t("login.title.welcome")}</p>
          <p>{t("login.welcomeDesc")}</p>
        </div>
        <div className="circle-box">
          <div className="blue-circle"></div>
          <div className="white-circle"></div>
        </div> */}
      </div>
      <div className="onBoardingForm registrationForm">
        <div className="top-banner">
          <img src={OMWLogoWhite} />
        </div>
        <div className="lng-dropdown">
          <LanguageDropdown />
        </div>
        {/* <div className="point-box">
          <p className="left-point"></p>
          <p className="middle-point"></p>
          <p className="right-point"></p>
        </div> */}
        <h2 className="onBoarding-title mt-4 mt-sm-0">{t("register")}</h2>
        <p className="onBoarding-text">{t("registration.title.description")}</p>

        <form className="reg-form" onSubmit={handleButtonClick}>
          <div className="userNameDiv">
            <div className="userName-firstName">
              <p className="inputLabel mt-2">{t("registration.firstName")}</p>
              {/* <InputComponent
            className="company-inp"
            placeholder={t("registration.firstName")}
            value={firstName}
            maxLength={20}
            onBlur={() =>
              firstName === ""
                ? setFirstNameError(errorMsgs.emptyName)
                : setFirstNameError("")
            }
            onChange={onChangeFirstName}
            errorMsg={firstNameError}
          /> */}
              <InputTypeText
                type="text"
                className="inputText"
                // placeholder={t("registration.firstName")}
                value={firstName}
                maxLength={20}
                onBlur={() =>
                  firstName === ""
                    ? setFirstNameError(errorMsgs.emptyName)
                    : setFirstNameError("")
                }
                onChange={onChangeFirstName}
                // errorMsg={firstNameError}
                errorMsg={firstNameError && `${t("errorMsgs.emptyFirstName")}`}
              />
            </div>
            <div className="userName-lastName">
              <p className="inputLabel mt-2">{t("registration.lastName")}</p>
              {/* <InputComponent
            className="company-inp"
            placeholder={t("registration.lastName")}
            value={lastName}
            maxLength={20}
            onBlur={() =>
              lastName === ""
                ? setLastNameError(errorMsgs.emptyName)
                : setLastNameError("")
            }
            onChange={onChangeLastName}
            errorMsg={lastNameError}
          /> */}
              <InputTypeText
                type="text"
                className="inputText"
                // placeholder={t("registration.lastName")}
                value={lastName}
                maxLength={20}
                onBlur={() =>
                  lastName === ""
                    ? setLastNameError(errorMsgs.emptyName)
                    : setLastNameError("")
                }
                onChange={onChangeLastName}
                // errorMsg={lastNameError}
                errorMsg={lastNameError && `${t("errorMsgs.emptyLastName")}`}
              />
            </div>
          </div>

          <p className="inputLabel mt-2">{t("registration.companyName")}</p>
          {/* <InputComponent
            className="company-inp"
            placeholder={t("registration.companyName")}
            value={companyName}
            maxLength={30}
            onBlur={() => companyName === "" ? setCompanyNameError(errorMsgs.emptyName) : setCompanyNameError("")}
            onChange={onChangeCompanyName}
            errorMsg={companyNameError}
          /> */}
          <InputTypeText
            type="text"
            className="inputText"
            // placeholder={t("registration.companyName")}
            value={companyName}
            maxLength={30}
            onBlur={() =>
              companyName === ""
                ? setCompanyNameError(errorMsgs.emptyName)
                : setCompanyNameError("")
            }
            onChange={onChangeCompanyName}
            // errorMsg={companyNameError}
            errorMsg={companyNameError && `${t("errorMsgs.emptyCompanyName")}`}
          />

          <p className="inputLabel mt-2">{t("registration.companyAddress")}</p>
          {/* <InputComponent

            className="companyadd-inp"
            placeholder={t("registration.companyAddress")}
            value={companyAddress}
            maxLength={50}
            onBlur={() => companyAddress === "" ? setCompanyAddressError(errorMsgs.emptyAddress) : setCompanyAddressError("")}
            onChange={onChangeCompanyAddress}
            errorMsg={companyAddressError}
          /> */}
          <InputTypeText
            type="text"
            className="inputText"
            // placeholder={t("registration.companyAddress")}
            value={companyAddress}
            maxLength={50}
            onBlur={() =>
              companyAddress === ""
                ? setCompanyAddressError(errorMsgs.emptyAddress)
                : setCompanyAddressError("")
            }
            onChange={onChangeCompanyAddress}
            // errorMsg={companyAddressError}
            errorMsg={companyAddressError && `${t("errorMsgs.emptyCompanyAddress")}`}
          />

          <p className="inputLabel mt-2">{t("registration.email")}</p>
          {/* <InputComponent

            type="email"
            className="reg_email-inp"
            placeholder={t("registration.emailExample")}
            value={email}
            onBlur={
              (e) => isValidEmailOnBlur(e.target.value)
              // email === ""
              //   ? setEmailError(errorMsgs.emptyEmail)
              //   : setEmailError("")
            }
            onChange={onChangeEmail}
            errorMsg={emailError}
          /> */}
          <InputTypeText
            type="email"
            className="inputText"
            // placeholder={t("registration.emailExample")}
            value={email}
            onBlur={
              (e) => isValidEmailOnBlur(e.target.value)
              // email === ""
              //   ? setEmailError(errorMsgs.emptyEmail)
              //   : setEmailError("")
            }
            onChange={onChangeEmail}
            // errorMsg={emailError}
            errorMsg={emailError && `${t("errorMsgs.emptyEmail")}`}
          />

          <p className="inputLabel mt-2">{t("registration.phoneNo")}</p>

          <PhoneInput
            inputClass="countryPhoneInput"
            country={"us"}
            value={phoneNumber}
            onChange={(value, country) => onChangePhoneNo(value, country)}
            onBlur={() => phoneNumber === "" ? setPhoneNumberError(errorMsgs.emptyPhone) : setPhoneNumberError("")}
          />
          <span className="errorMsg">
            {/* {phoneNumberError ? phoneNumberError : ""} */}
            {phoneNumberError ? t("errorMsgs.emptyPhone") : ""}
          </span>

          {/* <InputComponent
            className="phone-inp"
            placeholder="+3 (80) 00 000 00 00"
            value={phoneNumber}
            onBlur={() =>
              phoneNumber === ""
                ? setPhoneNumberError(errorMsgs.emptyPhone)
                : setPhoneNumberError("")
            }
            onChange={onChangePhoneNo}
            errorMsg={phoneNumberError}
          /> */}

          
          <p className="inputLabel mt-2">{t("registration.createPass")}</p>
          {/* <InputComponent
            className="createpass-inp"
            placeholder={t("registration.createPass")}
            type="password"
            value={password}
            onBlur={
              (e) => isValidPassOnBlur(e.target.value)
              // password === ""
              //   ? setPasswordError(errorMsgs.emptyPassword)
              //   : setPasswordError("")
            }
            onChange={onChangePassword}
            errorMsg={passwordError}
          /> */}









          {/* <InputTypeText
            className="inputText"
            // placeholder={t("registration.createPass")}
            type="password"
            value={password}
            onBlur={
              (e) => isValidPassOnBlur(e.target.value)
              // password === ""
              //   ? setPasswordError(errorMsgs.emptyPassword)
              //   : setPasswordError("")
            }
            onChange={onChangePassword}
            errorMsg={passwordError}
          /> */}

          <InputTypeSecure
            type={`${isShowCreatePass ? 'password' : 'text'}`}
            className="inputTextBox inputPass"
            value={password}
            onBlur={(e: any) => isValidPassOnBlur(e.target.value)
            }
            onChange={onChangePassword}
            // errorMsg={passwordError}
            errorMsg={passwordError && `${t("errorMsgs.emptyPassword")}`}
          />

          <span className="passwordReqText">
            {t("errorMsgs.emptyPasswordReg")}
          </span>
          
          <p className="inputLabel mt-4">{t("registration.confirmPass")}</p>
          <InputTypeSecure
            type={`${isShowCreatePass ? 'password' : 'text'}`}
            className="new_confirm-inp"
            placeholder={t("registration.confirmPass")}
            value={confirmPass}
            onBlur={(e: any) => isValidConfirmPassOnBlur(e.target.value)
              // confirmPass === ""
              //   ? setConfirmPassError(errorMsgs.confirmPassword)
              //   : setConfirmPassError("")
            }
            onChange={onChangeConfirmPassword}
            // errorMsg={ConfirmpassError}
            errorMsg={ConfirmpassError && `${t("errorMsgs.confirmPassword")}`}
          />
          {/* <InputTypeText
            className="inputText"
            type="password"
            //  placeholder={t("registration.confirmPass")}
            value={confirmPass}
            onBlur={
              (e) => isValidConfirmPassOnBlur(e.target.value)
              // confirmPass === ""
              //   ? setConfirmPassError(errorMsgs.confirmPassword)
              //   : setConfirmPassError("")
            }
            onChange={onChangeConfirmPassword}
            errorMsg={ConfirmpassError}
          /> */}

          {/* <InputTypeSecure
              type={`${isShowConfirmPass ? 'password' : 'text'}`}
              className="inputTextBox inputPass"
              value={confirmPass}
              onBlur={(e:any) => isValidConfirmPassOnBlur(e.target.value)
              }
              onChange={onChangeConfirmPassword}
              errorMsg={ConfirmpassError}
                /> */}


          <p className="inputLabel mt-4">{t("registration.country")}</p>
          {/* <InputComponent
            type="text"
            placeholder={t("registration.country")}
            value={country}
            maxLength={30}
            onBlur={() => country === "" ? setCountryErrorMsg(errorMsgs.emptyCountry) : setCountryErrorMsg("")
            }
            onChange={onChangeCountry}
            errorMsg={countryErrorMsg}
          /> */}
          <Form.Select
            title="Select Country"
            className="enter-num-inp"
            aria-label="Default select example"
            onChange={(e)=>onChangeCountry(e.target.value)}
            onBlur={() => country === "" ? setCountryErrorMsg(errorMsgs.emptyCountry) : setCountryErrorMsg("")}
          // placeholder={t("registration.country")}
          >
           <option value="">{country ? country  :  t("addRecipient.form.selectCountry") }</option> 
             {countries && countries.map((ele, index: number) => (
              <option value={ele.country} key={index}>
                {ele.country}
              </option>
            ))}
          </Form.Select>
          <span className="errorMsg">
            {/* {countryErrorMsg ? countryErrorMsg : ""} */}
            {countryErrorMsg ? t("errorMsgs.emptyCountry") : ""}
          </span>

          <p className="inputLabel mt-2">{t("registration.city")}</p>
          {/* <InputComponent
            type="text"
            placeholder={t("registration.city")}
            value={city}
            maxLength={20}
            onBlur={() => city === "" ? setCityErrorMsg(errorMsgs.emptyCity) : setCityErrorMsg("")}
            onChange={onChangeCity}
            errorMsg={cityErrorMsg}
          /> */}
          {/* <InputTypeText
            className="inputText"
            type="text"
            // placeholder={t("registration.city")}
            value={city}
            maxLength={20}
            onBlur={() =>
              city === ""
                ? setCityErrorMsg(errorMsgs.emptyCity)
                : setCityErrorMsg("")
            }
            onChange={onChangeCity}
            errorMsg={cityErrorMsg && `${t("errorMsgs.emptyCity")}`}
          /> */}
          <AutoCompleteInput
           inputData={citiesByCountry}
           value={city}
           onBlur={() => city === "" ? setCityErrorMsg(errorMsgs.emptyCity) : setCityErrorMsg("")}
           onChange={onChangeCity}
           errorMsg={cityErrorMsg && `${t("errorMsgs.emptyCity")}`}
           />


          <p className="inputLabel mt-4">{t("registration.postalCode")}</p>
          {/* <InputComponent
            type="text"
            placeholder={t("registration.postalCode")}
            value={postalCode}
            maxLength={20}
            onBlur={() =>
              postalCode === ""
                ? setPostalCodeErrorMsg(errorMsgs.emptyPostCode)
                : setPostalCodeErrorMsg("")
            }
            onChange={onChangePostalCode}
            errorMsg={postalCodeErrorMsg}
          /> */}
          <InputTypeText
            className="inputText"
            type="text"
            //  placeholder={t("registration.postalCode")}
            value={postalCode}
            maxLength={20}
            onBlur={() =>
              postalCode === ""
                ? setPostalCodeErrorMsg(errorMsgs.emptyPostCode)
                : setPostalCodeErrorMsg("")
            }
            onChange={onChangePostalCode}
            // errorMsg={postalCodeErrorMsg}
            errorMsg={postalCodeErrorMsg && `${t("errorMsgs.emptyPostCode")}`}
          />

          <p className="inputLabel mt-2">
            {t("registration.companyRegistrationNumber")}
          </p>
          {/* <InputComponent
            type="text"
            placeholder={t("registration.companyRegistrationNumber")}
            value={companyRegNumber}
            maxLength={20}
            onBlur={() => companyRegNumber === "" ? setCompanyRegNumberErrorMsg(errorMsgs.emptyCompanyRegNo) : setCompanyRegNumberErrorMsg("")}
            onChange={onChangeCompanyRegNumber}
            errorMsg={companyRegNumberErrorMsg}
          /> */}
          <InputTypeText
            className="inputText"
            type="text"
            // placeholder={t("registration.companyRegistrationNumber")}
            value={companyRegNumber}
            maxLength={20}
            onBlur={() => companyRegNumber === "" ? setCompanyRegNumberErrorMsg(errorMsgs.emptyCompanyRegNo) : setCompanyRegNumberErrorMsg("")}
            onChange={onChangeCompanyRegNumber}
            // errorMsg={companyRegNumberErrorMsg}
            errorMsg={companyRegNumberErrorMsg && `${t("errorMsgs.emptyCompanyRegNo")}`}
          />

          <p className="inputLabel mt-2">{t("registration.companyLogo")}</p>

          {CompanyLogoBase64 && (
            <img
              className="componyLogo"
              style={{ width: 100, marginRight: "23px", border: "solid 1px" }}
              src={CompanyLogoBase64}
              alt="logo"


            />
          )}
          <input
            type="file"
            accept=".png, .jpg, .jpeg"
            onChange={handleLogoUpload}
          />
          {/* <span className="errorMsg">{companyLogoError}</span> */}
          <span className="errorMsg">{companyLogoError && t("errorMsgs.emptyLogo")}</span>

          <div className="terms-conditions">
            {/* <input
              style={{ marginRight: 5 }}
              type="checkbox"
              id="agree"
              className="terms-conditionCheckBox"
              onChange={checkboxHandler}
            /> */}
            <Form.Check
              type={"checkbox"}
              id={`default-checkbox`}
              onChange={checkboxHandler}

            // label={`Remember Me`}
            />
            <label className="term-condition-text" htmlFor="agree">
              {" "}
              {t("registration.termCondition")}{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={
                  "https://www.b4bpayments.com/prepaid/terms-and-conditions/"
                }
              >
                <b className="term-condition-link">{t("registration.termConditionText")}</b>
              </a>
            </label>
          </div>
          <BasicButton
            type="submit"
            style={{ marginTop: '30px' }}
            text={t("registration.createAcc")}
            isLoading={loading}
            disabled={!agree}
          />
          {/* <Button disabled={!agree} type="submit" className="reg_btn">
            {loading ? (
              <Spinner animation={"border"} />
            ) : (
              `${t("registration.createAcc")}`
            )}
          </Button> */}
          <div className="onBoardingFooterText mt-3">
            <p>
              {t("login.haveAccount")}
              <span onClick={() => navigate("/")}> {t("signin")}</span>
            </p>
          </div>
          {/* <Button onClick={() => navigate("/")} className="reg_btn">
            {t("registration.backToLogin")}
          </Button> */}
        </form>
      </div>
    </div>
  );
};
export default Registration;
