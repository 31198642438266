import React from "react";
import { t } from "i18next";
import { Modal, ModalBody } from "react-bootstrap";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";

const LegalInformationModal = (props: {
  showLegalInfo: boolean,
  handleClose: any,
}) => {
  const { showLegalInfo, handleClose } = props;
  const { t } = useTranslation();

  return (
    <Modal show={showLegalInfo} onHide={handleClose} centered className="card-settings-modal-height">
      <Modal.Header style={{ display: 'flex', alignItems: 'center', borderBottom: 0 }}>
        <div>
          <Modal.Title>Legal Information & Issuer Statement</Modal.Title>
        </div>
        <IconButton
          style={{ backgroundColor: '#006ff4', color: 'white', width: '22px', height: '22px' }}
          onClick={handleClose}
        >
          <CloseIcon style={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Modal.Header>
      <ModalBody className="pt-2 pb-2">
        <p className="cards-legal-modal-text">
          © Copyright OneMoneyWay 2024. All rights reserved
        </p>
        <p className="cards-legal-modal-text">
          OneMoneyWay and onemoneyway.com are trading names of OMW Europe Limited. OMW Europe Limited is registered in England and Wales, Company Number 13651522 and is registered with The United Kingdom Information Commissioner&apos;s Office with ICO registration number ZB449652.
        </p>
        <p className="cards-legal-modal-text">
          Mastercard is a registered trademark, and the circles design is a trademark of Mastercard International Incorporated. The Card is issued by B4B Payments pursuant to license by Mastercard International Inc. B4B Payments is authorised by the Financial Conduct Authority to conduct electronic money service activities under the Electronic Money Regulations 2011 (Ref: 930619) and by the Bank of Lithuania as an Electronic Money Institution (Licence No: 76) under the Law on Electronic Money and Electronic Money Institutions 2011.
        </p>
        <p className="cards-legal-modal-text">
          If you need to speak to our customer service team, please reach out to your dedicated account manager or call B4B Payments and follow the instructions.
          <br />
          <span style={{ fontWeight: "600" }}>Europe: +44 20 3475 5351 </span>
          <br />
          <span style={{ fontWeight: "600" }}>Americas: +1 604 200 0554</span>
        </p>
        {/* <p className="cards-legal-modal-text">
          The customer service can be contacted via the methods below:<br />
          Business days between <span style={{ fontWeight: "bold" }}>9am</span> to <span style={{ fontWeight: "bold" }}>5.30pm CET</span>:  <span style={{ fontWeight: "bold" }}>+4589876556</span><br />
          By e-mail: <span style={{ fontWeight: "bold" }}>Support@onemoneyway.com</span><br />
          On the user interface: <span style={{ fontWeight: "bold" }}>app.onemoneyway.com</span><br />
        </p> */}

        <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
          <p style={{ textAlign: "center" }}>
            <a href="https://www.iubenda.com/privacy-policy/95230831/full-legal" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
          </p>
          <p style={{ textAlign: "center" }}>
            <a href="https://www.b4bpayments.com/prepaid/terms-and-conditions/" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
          </p>
        </div>
      </ModalBody>
    </Modal>
  )
};

export default LegalInformationModal;

