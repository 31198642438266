const getS3BaseUrl = () => {
  let URL = "";

  if (process.env.REACT_APP_ENV === "staging")
    URL = "https://bkimage.onemoneyway.com/";

  if (process.env.REACT_APP_ENV === "production")
    URL = "https://bkimage.onemoneyway.com/";

  if (process.env.REACT_APP_ENV === "dev")
    URL = "https://bkimage.onemoneyway.com/";

  return URL;
};

const staticRoles = {
  ADMIN: "ADMIN",
  ACCOUNTANT: "ACCOUNTANT",
};

export { getS3BaseUrl, staticRoles };
