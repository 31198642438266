import React from 'react'
import CardTemplate from "../../../assets/images/cardAssets/omwCardTemplate.svg"

const PendingCardsKYBScreen = () => {
  return (
    <div className="cardsKYBScreen">
      <img
        src={CardTemplate}
        className={`cardTemplateViewDetails`}
      />
      <p style={{ fontSize: "24px", fontWeight: "600", margin: 0 }}>
        Your OneMoneyWay cards are on the way...
      </p>
      <p style={{ fontSize: "18px", margin: 0 }}>
        Once your account details have been associated with your user, you will be able to issue cards for yourself!
      </p>
      <p style={{ fontSize: "15px", color: "rgba(173, 182, 192, 1)", margin: 0 }}>
        For any questions, kindly send an email to support@onemoneyway.com
      </p>
    </div>
  )
}

export default PendingCardsKYBScreen;