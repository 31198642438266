import axios from "axios";
import BASE_URL from "../utils/baseURL";

export async function apiHelper<T = any>(
  url: string,
  method: string,
  body: object = {},
  headers: object = {
    "Content-Type": "application/json",
  }
) {
  try {
    // console.log("BASE_URL IS", BASE_URL)
    url = `${BASE_URL}${url}`;
    const config: any = {
      method,
      url,
      headers,
    };
    if (method == "post" || method == "put" || method == "patch") {
      config["data"] = body;
    }
    // console.log("CONF", config);
    const response = await axios<T>(config);
    return response;
  } catch (e: any) {
    // throw new Error(e.response.data.message);
    throw new Error(e.response.data.message, { cause: e.response.data.message });
  }
}
