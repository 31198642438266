import React from "react";
import { PathStrConst } from "..";
import Addbeneficiary from "../../pages/Addbeneficiary";
import BankAccounts from "../../pages/BankAccounts";
import PrepaidCards from "../../pages/BankAccountCard";
import Convert from "../../pages/Convert";
import DownloadPDF from "../../pages/downloadPDF";
import ForgotPassword from "../../pages/ForgotPassword";
import ResetPassword from "../../pages/ForgotPassword/ResetPassword";
import GeneralInformation from "../../pages/GeneralInfromation";
import PrivacyPolicy from "../../pages/Privacy Policy";
import OMWFees from "../../pages/Privacy Policy/OMWFees";
import Registration from "../../pages/Registration";
import SendFunds from "../../pages/SendFunds";
import SignIn from "../../pages/SignIn";
import Verfication from "../../pages/Verification";
import Invoice from '../../pages/Invoice'
import EditRegistration from "../../pages/Registration/EditRegistration";
import InterestAccount from "../../pages/InterestAccount/Index";
import Statement from "../../pages/Statement";
import CardPayments from "../../pages/CardPayments";
import { staticRoles } from '../../utils/constants';
const { ADMIN, ACCOUNTANT } = staticRoles

export const publicRoutes = [
  { path: PathStrConst.signin, component: <SignIn /> },
  { path: PathStrConst.signup, component: <Registration /> },
  { path: PathStrConst.forgotPassword, component: <ForgotPassword /> },
  { path: PathStrConst.resetPassword, component: <ResetPassword /> },
  { path: PathStrConst.verification, component: <Verfication /> },
  
];

export const privateR = [
  { path: PathStrConst.bankAccount, component: <BankAccounts />, roles: [ADMIN, ACCOUNTANT] },
  { path: PathStrConst.bankAccountCards, component: <PrepaidCards />, roles: [ADMIN, ACCOUNTANT]},
  { path: PathStrConst.generalInformation, component: <GeneralInformation />, roles: [ADMIN, ACCOUNTANT]},
  { path: PathStrConst.convert, component: <Convert />, roles: [ADMIN]},
  { path: PathStrConst.sendFunds, component: <SendFunds />, roles: [ADMIN, ACCOUNTANT]},
  { path: PathStrConst.addbeneficiary, component: <Addbeneficiary />, roles: [ADMIN, ACCOUNTANT]},
  { path: PathStrConst.PrivacyPolicy, component: <PrivacyPolicy />, roles: [ADMIN, ACCOUNTANT]},
  { path: PathStrConst.OmwFees, component: <OMWFees />, roles: [ADMIN, ACCOUNTANT]},
  { path: PathStrConst.downloadPdf, component: <DownloadPDF />, roles: [ADMIN, ACCOUNTANT]},
  { path: PathStrConst.invoice, component: <Invoice />, roles: [ADMIN, ACCOUNTANT]},
  { path: PathStrConst.editregistration, component: <EditRegistration />, roles: [ADMIN, ACCOUNTANT]},
  { path: PathStrConst.interestaccount, component: <InterestAccount />, roles: [ADMIN, ACCOUNTANT]},
  { path: PathStrConst.statement, component: <Statement />, roles: [ADMIN, ACCOUNTANT]},
  { path: PathStrConst.CardPayments, component: <CardPayments />, roles: [ADMIN, ACCOUNTANT]},
];
