import React, { useState, useEffect } from "react";
import { PieChart } from "react-minimal-pie-chart";
import { BsArrowDownShort } from "react-icons/bs";
import ExpenseCard from "../ExpenseCard";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getUserExpense } from "../../../redux/toolkit/expenseCard/operation";
import FilterByDays from "../filterWithDays";
import "./index.css";
import { Spinner } from "react-bootstrap";
import COLORS from "../../../utils/colors";
import { ArrowDownIcon } from "../../../constants/Images";

const ExpeneAndPayments = () => {
  const dispatch = useDispatch<AppDispatch>();
  const accountData: any = localStorage.getItem("AccountData");
  const parsedData = JSON.parse(accountData);
  const [loader, setLoader] = useState(false);
  const [accountId, setAccountId] = useState<any>(
    localStorage.getItem("accountId")
  );
  const expenseCardSelector = useSelector(
    (state: RootState) => state.expenseDetails.data
  );
  const [expense, setExpense] = useState(true);
  const [expenseDetails, setExpenseDetails] = useState<any>([]);
  console.log("expenseDetails: ", expenseDetails);

  const { t } = useTranslation();
  useEffect(() => {
    dispatch(getUserExpense(accountId));
    setLoader(true);
  }, [dispatch, loader, accountId]);

  useEffect(() => {
    if (expenseCardSelector) {
      setExpenseDetails(expenseCardSelector);
      setLoader(false);
      console.log("EXP: ");
    }
  }, [expenseCardSelector, expenseDetails, loader]);
  // console.log("Selector", expenseDetails);
  useEffect(() => {
    document.addEventListener("CardValue", (event: any) => {
      const { detail } = event;
      setAccountId(detail.accountId);
    });
  }, []);
  // console.log("state", expenseDetails);

  return (
    <div className="expense-payment p-3">
      <div className="expense-payment-text">
        <span className="expense-text">{t("rightSideBar.monthlyRevenue")}</span>
        {/* <span className="expense-text">{t("rightSideBar.payments")}</span> */}
      </div>
      <div className="filter-month-main">
        <span className="month-filter-text">
          {t("rightSideBar.month")}
          {/* <BsArrowDownShort size={15} /> */}
          <img
            src={ArrowDownIcon}
            width={8}
            height={12}
            style={{ marginLeft: "5px" }}
          />
        </span>
      </div>
      <div className="expenses-main-contents">
        {loader ? (
          <Spinner />
        ) : expenseDetails.data ? (
          <>
            <div className="main-chart-content">
              <PieChart
                data={[
                  // {
                  //   title: "Spent",
                  //   value: expenseDetails.data.data.spent,
                  //   // value: 8,
                  //   // color: COLORS.pimary,
                  //   color: "#006FF4",
                  // },
                  {
                    title: "Remainder",
                    value: expenseDetails.data.data.remainder,
                    // value: 2,
                    color: "#0097F4",
                  },
                  {
                    title: "Spent",
                    value: expenseDetails.data.data.spent,
                    // value: 8,
                    // color: COLORS.pimary,
                    color: "#006FF4",
                  },
                ]}
                label={() =>
                  expenseDetails.data.data.spent.toFixed(2) +
                  " " +
                  parsedData?.currency
                }
                labelStyle={() => ({
                  title: "Spent",
                  fontSize: "9px",
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                })}
                labelPosition={0}
                lineWidth={23}
                style={{
                  fontSize: 6,
                  overflow: "visible",
                  padding: 20,
                  height: 200,
                  // width: 200,
                }}
                rounded
                animate
              />
            </div>
            <div className="limit-remainder-main">
              <div>
                <p className="limit-remainder-text">
                  {t("rightSideBar.limit")}
                </p>
                <p className="limit-amount">{expenseDetails.data.data.limit}</p>
              </div>
              <div>
                <p className="limit-remainder-text">
                  {t("rightSideBar.remainder")}
                </p>
                <p className="limit-amount">
                  {expenseDetails.data.data.remainder.toFixed(2)}
                </p>
              </div>
            </div>
            {/* <div className="limit-remainder-amount">
              
              </div> */}
          </>
        ) : (
          <p>{t("rightSideBar.noData")}</p>
        )}
        {/* <div className="operations-cardss">
            {expenseDetails &&
              expenseDetails
                .slice(0, 3)
                .map(
                  (
                    ele: { place: string; operation: string; amount: string },
                    index: React.Key
                  ) => {
                    return (
                      <ExpenseCard
                        key={index}
                        place={ele.place}
                        operation={ele.operation}
                        amount={ele.amount}
                      />
                    );
                  }
                )}
          </div> */}
      </div>
      {/* {expense ? (
        
      ) : (
        <div className="operations-cardss">
          {expenseDetails &&
            expenseDetails.slice(0, 3).map(() => {
              // eslint-disable-next-line react/jsx-key
              return <FilterByDays />;
            })}
        </div>
      )} */}
    </div>
  );
};

export default ExpeneAndPayments;
