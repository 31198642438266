import React, { useEffect, useState } from "react";
import { Col, Form, Modal, ModalFooter } from "react-bootstrap";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CurrencyFlag from "react-currency-flags";
import { useTranslation } from "react-i18next";
import "../../index.css"
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { FormattedCard, SelectedBusinessAccount, LoadCardData } from "../../interface";
import BasicButton from "../../../../components/shared/Buttons/Basic";
import InputTypeText from "../../../../components/shared/Input/Basic/InputTypeText";

const UnloadMoneyModal = (props: {
  showUnloadMoneyModal: boolean,
  setShowUnloadMoney: React.Dispatch<React.SetStateAction<boolean>>,
  handleClose: () => void,
  selectedCard: FormattedCard;
}) => {
  const { showUnloadMoneyModal, setShowUnloadMoney, handleClose, selectedCard } = props;
  const accountSelector: any = useSelector(
    (state: RootState) => state.getAllAccounts.data
  );
  const [unloadCardData, setUnloadCardData] = useState<LoadCardData>({
    amount: 0,
    description: "",
  });
  const [selectedBusinessAcc, setSelectedBusinessAcc] = useState<SelectedBusinessAccount>({});


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "amount") {
      // Remove non-numeric and non-decimal characters
      const numericValue = value.replace(/[^\d.]/g, '');
      // Remove leading zeros except for the first character if it's a decimal point
      const cleanedValue = numericValue.replace(/^0+(?=\d)|\.0*$/g, '');
      // Ensure there is at most one decimal point
      const decimalCount = cleanedValue.split('.').length - 1;
      let limitedValue = cleanedValue;
      if (decimalCount > 1) {
        // If more than one decimal point, keep only the first one
        limitedValue = cleanedValue.replace(/\.([^.]*)$/, '$1');
      }
      // Limit the length of the number to 10 digits
      const limitedLengthValue = limitedValue.slice(0, 10);
      // Convert the value to a number
      const numVal = Number(limitedLengthValue);
      setUnloadCardData({ ...unloadCardData, [name]: numVal })
    } else {
      setUnloadCardData({ ...unloadCardData, [name]: value })
    }
  }

  const handleClick = (event: any) => {
      setShowUnloadMoney(false);
  };

  useEffect(() => {
    if (accountSelector?.data?.data) {
      const matchingAccount = accountSelector.data.data.find((acc: any) => acc.accB4BUUID === selectedCard?.accB4BUUID);
      if (matchingAccount) {
        setSelectedBusinessAcc(matchingAccount)
      } else {
        setSelectedBusinessAcc({})
      }
    }
  }, [accountSelector]);

  return (
    <Modal
      show={showUnloadMoneyModal}
      onHide={() => {
        setUnloadCardData({ amount: 0, description: "" });
        return handleClose();
      }}
      centered
      style={{ marginTop: '8px' }}
    >
      <Modal.Header style={{ borderBottom: "0px" }}>
        <div>
          <Modal.Title>Unload Money</Modal.Title>
        </div>
        <IconButton
          style={{ backgroundColor: '#006ff4', color: 'white', width: '22px', height: '22px' }}
          onClick={() => {
            setUnloadCardData({ amount: 0, description: "" });
            return handleClose();
          }}
        >
          <CloseIcon style={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Modal.Header  >
      <Modal.Body style={{ borderTop: "0px" }}>
        <p style={{ margin: 0, fontWeight: "600", marginBottom: "10px" }}>Business Account:</p>
        <Col className="col-12 p-2"
          style={{
            border: '1px solid #D7F0FF',
            borderRadius: "10px",
          }}>
          {selectedBusinessAcc.accountName ?
            <p style={{ margin: 0 }} className="currencyFlag">
              {selectedBusinessAcc.accountName}
            </p>
            :
            <p style={{ margin: 0, color: "gray" }}>
              --- No Corresponding Business Accounts ---
            </p>
          }
        </Col>

        <p style={{ margin: 0, fontWeight: "600", marginTop: "20px" }}>Amount to Unload</p>
        <div className="addMoney-AmountToTopUp mt-2">
          <Col className="col-3"
            style={{
              border: '1px solid #D7F0FF',
              borderRadius: "10px",
            }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                height: '100%'
              }}
            >
              <CurrencyFlag currency={selectedCard.currency} />
              <p style={{ margin: 0 }} className="currencyFlag">{selectedCard.currency}</p>
            </div>
          </Col>

          <Col className="col-9">
            <div className="addMoney-AmountToTopUp-AmountInput">
              <InputTypeText
                compulsoryValue
                type="number"
                inputContainerMargins="0"
                placeholder="Amount"
                name="amount"
                value={unloadCardData.amount}
                onChange={handleChange}
              />
            </div>
          </Col>
        </div>

        <p style={{ margin: 0, fontWeight: "600", marginTop: "20px" }}>Description</p>
        <InputTypeText
          type="text"
          inputContainerMargins="0"
          placeholder="Will appear in statement"
          value={unloadCardData.description}
          name="description"
          onChange={handleChange}
          maxLength={250}
        />
      </Modal.Body>

      <ModalFooter style={{ borderTop: "0px" }}>
        <BasicButton
          type="button"
          onClick={handleClick}
          disabled={selectedBusinessAcc.accountName && (unloadCardData.amount !== 0) ? false : true}
          text="Confirm"
        />
      </ModalFooter>
    </Modal >
  )
};

export default UnloadMoneyModal;