import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { format, isValid } from "date-fns";
import COLORS from "../../../utils/colors";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const currencySymbolMap = require("currency-symbol-map");
// import { getSymbolFromCurrency } from 'currency-symbol-map';
// import { updatePaymentStatus } from "../../../redux/toolkit/sendFunds/operations";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { Spinner } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { Row, Col, } from "react-bootstrap";
import { dataaaaa } from "./data";
import "./index.css"
import { SearchFilter, DownloadFilter, FilterLines, PrintFilter } from "../../../constants/Images";
import { CiViewColumn } from "react-icons/ci";
import ToggleSwitch from "../ToggleButton";
import CustomPagination from "../CustomPagination";






export const CustomDataTable = ({ data,updatePaymentStatus,getRecPayments }: any) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
    const dispatch = useDispatch<AppDispatch>();

    const getMuiTheme = () =>
        createTheme({
            components: {
                MuiTableCell: {
                    styleOverrides: {
                        "root": {
                            padding: '10px',
                            fontFamily: 'Montserrat',
                            fontSize: 18,
                            // backgroundColor: '#CDCAC6',
                        }
                    }
                },
                MuiTypography: {
                    styleOverrides: {
                      root: {
                        fontFamily: 'Montserrat',
                        fontSize: '18px',
                        fontWeight: 500,
                        color: '#ADB6C0',
                      }
                    }
                  },
                MuiButton: {
                    styleOverrides: {
                        root: {
                            fontFamily: 'Montserrat',
                            // fontSize:14,
                            fontWeight: "600",
                            textAlign: 'left',
                            paddingLeft: 0
                        },
                    }
                },
                MUIDataTableHeadCell: {
                    styleOverrides: {
                        root: {
                            fontWeight: "500 !important",
                        }

                    }
                },
                MuiToolbar: {
                  styleOverrides:{
                    root: {
                    display:'flex'
                  }}
                },
                MuiList:{
                    styleOverrides: {
                        root: {
                            display:"flex",
                            flexDirection:'column',
                            background:'#ffffff',
                        }
                    }
                },
                MUIDataTableFilter:{
                    styleOverrides:{
                      root:{fontFamily:'Montserrat'},
                      reset:{
                        // backgroundColor:"red",
                        '& button': { 
                          marginLeft:'10px',
                          paddingLeft:"6px",
                        }
                      },
                    }
                  },
                  MuiFormControl:{
                    styleOverrides:{
                      root:{
                        padding:'35px 20px 20px 20px'
                      }
                    }
                  },
            }
        });

    const columns = [
        {
            name: "recurringDate",
            label: "Date / Day",
            options: {
                filter: true, sort: false,
                customBodyRenderLite: (dataIndex: any) => {
                    return <div style={{ minWidth: "max-content" }} >{
                        isValid(new Date(data[dataIndex]?.recurringDate)) ?
                            format(new Date(data[dataIndex]?.recurringDate), "yyyy-MM-dd") :
                            data[dataIndex]?.recurringDate
                    }
                    </div>
                }
            },
        },
        {
            name: "payment_reason",
            label: "Reason",
            options: { filter: true, sort: false },
        },
        {
            name: "payment_amount",
            label: "Amount",
            options: {
                filter: true, sort: false,
                customBodyRenderLite: (dataIndex: any) => {
                    return <div style={{ minWidth: "55px" }} >{data[dataIndex]?.payment_amount}</div>
                }
            },
        },
        {
            name: "payment_currency",
            label: "Currency",
            options: { filter: true, sort: false },
        },
        {
            name: "recurringType",
            label: "Recurring Type",
            options: { filter: true, sort: false },
        },
        {
            name: "status",
            label: "Status",
            options: { filter: true, sort: false },
        },
        {
            name: "payment_amount",
            label: "Action",
            options: {
                filter: true, sort: false,
                customBodyRenderLite: (dataIndex: any) => {
                    return <ToggleSwitch onChangeHandler={() => startStopHandler(data[dataIndex])} isEnable={data[dataIndex]?.isRecurring ?  true : false}/>
                    // <button
                    //     style={{ background: !data[dataIndex]?.isRecurring ? COLORS.pimary : "#f40f00", color: '#ffffff', padding: '10px', borderRadius: '8px', border: 'none',cursor:isLoading ? "":"pointer" }}
                    //     onClick={() => startStopHandler(data[dataIndex])}
                    //     disabled={isLoading}
                    // >
                    //     {/* {(isLoading && data[dataIndex]) && <Spinner animation={"border"} size="sm" style={{marginRight:"5px"}}/> } */}
                    //     { !data[dataIndex]?.isRecurring ?  "Start" : "Stop"}

                    // </button>
                }
            },
        },
    ];

    const startStopHandler = async (data: any) => {
        try {
            setIsLoading(true)
            // console.log("startStopHandler =>", data);
            const payload = { id: data._id, status: data.isRecurring ? false : true }
            const res = await dispatch(updatePaymentStatus(payload))
            await getRecPayments();
            // console.log(res)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
        }
    }

    const renderIcon = (icon: string) => <img src={icon} alt="" />
    const customIcons: any = {
        SearchIcon: () => renderIcon(SearchFilter),
        PrintIcon: () => renderIcon(PrintFilter),
        DownloadIcon: () => renderIcon(DownloadFilter),
        ViewColumnIcon: () => <CiViewColumn style={{ color: 'black', fontSize: "larger" }} />,
        FilterIcon: () => renderIcon(FilterLines),
      }

    return (
        <>
            {/* <Row> */}
            {/* Content area collomns */}
            {/* <Col sm={12} md={12} lg={12}> */}
            {/* <Card className="p-3 p-xl-5 my-3 my-xl-4"> */}
            <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    title={<p className="mb-0 recuringTbl">Recurring Payments</p>}
                    data={data}
                    columns={columns}
                    components={{ icons: customIcons }}
                    options={{
                        selectableRows: "multiple",
                        selectableRowsHideCheckboxes: true,
                        selectableRowsOnClick: false,
                        // fixedHeader:true
                        responsive: 'standard',
                        textLabels:{ 
                            body:{
                              noMatch:'Sorry, no matching records found'
                            }
                          },
                          customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => (
                            <CustomPagination
                              count={count}
                              page={page}
                              rowsPerPage={rowsPerPage}
                              onChangePage={changePage}
                              setCurrentPage={setCurrentPage}
                            />
                          )
                    }}
                />
            </ThemeProvider>
            {/* </Card> */}
            {/* </Col> */}
            {/* </Row> */}
        </>
    );
};

