import React, { useEffect, useRef, useState } from "react";
import HamburgerMenu from "../../components/shared/HamburgerMenu";
import LeftSideBar from "../../components/shared/LeftSidebar";
import PrepaidCard from "./PrepaidCard";
import { useTranslation } from "react-i18next";
import RightSideBar from "../../components/shared/RightSidebar";
import "./index.css";
import { Row } from "react-bootstrap";
import PendingCardsKYBScreen from "./PendingCardsKYBScreen";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { OMWCard, CardOrder, FormattedCardOrder } from "../../redux/toolkit/cards/interface";
import { getAllCardOrders, getAllCards } from "../../redux/toolkit/cards/operation";
import cardSelectors from "../../redux/toolkit/cards/selector";
import { FormattedCard, LoadCardData, SelectedBusinessAccount } from "./interface";
import SettingButtons from "./CardsSettings/SettingButtons";
import { Divider, Skeleton } from "@mui/material";
import CardSettings from "./CardsSettings";
import { useLocation } from 'react-router-dom';

// Import icons for main card actions
import AddMoneyIcon from "../../assets/images/cardAssets/addMoney.svg";
import CardDetailsIcon from "../../assets/images/cardAssets/cardDetails.svg";
import CardSettingsIcon from "../../assets/images/cardAssets/cardSettings.svg";
import CardTemplate from "../../assets/images/cardAssets/omwCardTemplate.svg"

import ApplyForCardModal from "./CardSettingModals/ApplyForCardModal";
import ConfirmPasswordModal from "./CardSettingModals/ConfirmPasswordModal";
import CardDetailsModal from "./CardSettingModals/CardDetailsModal";
import AddMoneyModal from "./CardSettingModals/AddMoneyModal";
import TopUpSummaryModal from "./CardSettingModals/TopUpSummaryModal";

import CardTransactionTable from "./CardTransactionTable/";
import PrepaidCardOrders from "./PrepaidCardOrders";
import BasicButton from "../../components/shared/Buttons/Basic";
import IconButton from "../../components/shared/Buttons/IconButton";
import { MdAdd } from "react-icons/md";
import { getAllAccounts } from "../../redux/toolkit/getAllUserAccount/operation";
import { staticRoles } from '../../utils/constants'

const NoSelectedCardMessage = "Please select a card first"
const NoCardsMessage = "Please create a card first"

const PrepaidCards = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const location = useLocation();
  const [applyCard, setApplyCard] = useState<boolean>(false);
  const [showCardSettings, setShowCardSettings] = useState<boolean>(false);
  const [cards, setCards] = useState<FormattedCard[]>([]);
  const [cardOrders, setCardOrders] = useState<FormattedCardOrder[]>([]);
  const [selectedCardId, setSelectedCardId] = useState<string>("");
  const [selectedCard, setSelectedCard] = useState<FormattedCard>({
    b4bUserId: 0,
    cardId: "",
    maskedCardNumber: "",
    embossedName: "",
    currency: "",
    holderFirstName: "",
    holderLastName: "",
    cardType: "",
    status: "",
    availableBalance: 0,
    expiry: "",
    registered: false,
  });
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [passwordReauth, setPasswordReauth] = useState<boolean>(false);
  const [showAddMoney, setShowAddMoney] = useState<boolean>(false);
  const [showAddMoneySummary, setShowAddMoneySummary] = useState<boolean>(false);
  const [loadCardData, setLoadCardData] = useState<LoadCardData>({
    amount: 0,
    description: "",
  });
  const [viewAllTransactions, setViewAllTransactions] = useState<boolean>(false);

  // account to be used to card top-ups (B4B account)
  const [selectedBusinessAcc, setSelectedBusinessAcc] = useState<SelectedBusinessAccount>({});

  //kyb screen test vairable hardcoded true, make it false to see other screen
  const [pendingKYB] = useState<boolean>(localStorage.getItem("IsActiveUser") !== "true");

  const allCardsSelector: any = useSelector(cardSelectors.allCardsSelect);
  const cardOrdersSelector: any = useSelector(cardSelectors.cardOrdersSelect);
  const userID: any = localStorage.getItem("userId")!
  const roles: string[] = JSON.parse(localStorage.getItem('roles') || "[]");

  useEffect(() => {
    !allCardsSelector.data.data && dispatch(getAllCards({ userId: localStorage.getItem("userId")! }))
    !cardOrdersSelector.data.data && dispatch(getAllCardOrders({ userId: localStorage.getItem("userId")! }))
  }, []);

  // runs when all the card have been retrieved and formats into desired object
  useEffect(() => {
    if (allCardsSelector.status === "succeeded") {
      if (
        allCardsSelector.data.data &&
        allCardsSelector.data.data.length > 0
      ) {
        // Map over the allCards data and construct the formatted cards state
        const formattedCards: FormattedCard[] = allCardsSelector.data.data.map(
          (card: OMWCard) => {
            // Construct the formatted card object
            return {
              b4bUserId: card.b4bUserID,
              cardId: card.cardID,
              maskedCardNumber: card.lastDigits,
              embossedName: card.embossedName,
              currency: card.currency,
              holderFirstName: card.firstName,
              holderLastName: card.lastName,
              cardType: card.virtual ? "Virtual Card" : "Physical Card",
              status: card.status,
              availableBalance: card.balance,
              expiry: card.expiry,
              registered: card.registered,
              cardLabel: card?.cardLabel,
              accB4BUUID: card?.accB4BUUID
            };
          }
        );
        setCards(formattedCards);
        // Set the default selected card. The first card will be selected. 
        // If the selection is later changed, we do not want to force the first card, hence the check.
        if (selectedCardId === "") {
          setSelectedCard(formattedCards[0])
          setSelectedCardId(formattedCards[0].cardId)
        }
        // setCards([]);

        // IF USER IS VIEWING THE CARD IN THE SETTINGS,
        // THIS WILL PROVIDE A NEW FORMATTED CARD OBJECT TO REFRESH THE VIEW.
        if (selectedCardId !== "") {
          // Find the new card data based on ID
          const newSelectedCard: OMWCard =
            allCardsSelector.data.data.find(
              (card: OMWCard) => card.cardID === selectedCardId
            );
          const formattedCard: FormattedCard = {
            b4bUserId: newSelectedCard.b4bUserID,
            cardId: newSelectedCard.cardID,
            maskedCardNumber: newSelectedCard.lastDigits,
            embossedName: newSelectedCard.embossedName,
            currency: newSelectedCard.currency,
            holderFirstName: newSelectedCard.firstName,
            holderLastName: newSelectedCard.lastName,
            cardType: newSelectedCard.virtual ? "Virtual Card" : "Physical Card",
            status: newSelectedCard.status,
            availableBalance: newSelectedCard.balance,
            expiry: newSelectedCard.expiry,
            registered: newSelectedCard.registered,
            cardLabel: newSelectedCard?.cardLabel,
            accB4BUUID: newSelectedCard?.accB4BUUID
          };
          setSelectedCard(formattedCard)
        }
      } else {
        setCards([]);
      }
    }
  }, [allCardsSelector.data]);

  // runs when all the card orders have been retrieved and formats into desired object
  useEffect(() => {
    if (cardOrdersSelector.status === "succeeded") {
      if (
        cardOrdersSelector.data.data &&
        cardOrdersSelector.data.data.length > 0
      ) {
        // Map over the cardOrders data and construct the formatted objects
        const formattedCardOrders: FormattedCardOrder[] = cardOrdersSelector.data.data.map(
          (cardOrder: CardOrder, index: number) => {
            // Construct the formatted card order object
            return {
              id: cardOrder._id,
              userId: cardOrder.userID,
              accB4BUUID: cardOrder.accB4BUUID,
              cardDetails: cardOrder.cardDetails,
              orderDetails: cardOrder.orderDetails,
              cardActivated: cardOrder.cardActivated,
              createdAt: cardOrder.createdAt
            };
          }
        );
        setCardOrders(formattedCardOrders);
        // setCardOrders([]);
      } else {
        setCardOrders([]);
      }
    }
  }, [cardOrdersSelector.data]);

  // Triggers when the location.state changes, only happens when navigating from Create Card Now Promp
  useEffect(() => {
    if (location.state && location.state.applyCard) {
      setApplyCard(true);
    }
  }, [location.state]);

  const handleClose = () => {
    setApplyCard(false);
    setShowConfirmPassword(false);
    setPasswordReauth(false);
    setShowAddMoney(false);
    setShowAddMoneySummary(false);
  };

  const handleApplyCardClick = () => {
    setApplyCard(true);
  };

  const handleCardClick = (card: FormattedCard, cardId: string) => {
    setSelectedCard(card);
    setSelectedCardId(cardId); // Update the selected card's ID
  };

  const handlePassReauth = () => {
    setShowConfirmPassword(false);
    setPasswordReauth(true);
  };

  const CardPrimaryActions = [
    {
      mainIcon: AddMoneyIcon,
      name: "Add Money",
      onClick: () => {
        setShowAddMoney(true);
      },
      isDisabled: selectedCardId === "" ? "disabled" : "",
      tooltipMessage: cards.length === 0 ? NoCardsMessage : NoSelectedCardMessage,
    },
    {
      mainIcon: CardDetailsIcon,
      name: "Card Details",
      onClick: () => {
        setShowConfirmPassword(true);
      },
      isDisabled: selectedCardId === "" || (selectedCard && selectedCard.cardType === "Physical Card") ? "disabled" : "",
      tooltipMessage: cards.length === 0
        ? NoCardsMessage
        : selectedCardId === ""
          ? NoSelectedCardMessage
          : selectedCard && selectedCard.cardType === "Physical Card"
            ? "Only applicable to virtual cards"
            : "",
    },
    {
      mainIcon: CardSettingsIcon,
      name: "Card Settings",
      onClick: () => {
        setShowCardSettings(true);
      },
      isDisabled: selectedCardId === "" ? "disabled" : "",
      tooltipMessage: cards.length === 0 ? NoCardsMessage : NoSelectedCardMessage,
    },
  ];

  return (
    <>
      {/* <div className="hamburgerBackground">
        <HamburgerMenu name="bankAccountCards" />
      </div> */}
      <div className="Bank-info-main">
        {/* <div className="left_sidebar position-fixed">
          <LeftSideBar name="bankAccountCards" />
        </div> */}
        <div className="inner__content main-cards-content position-relative">
          <div className="container">
            <div className="row">
              {/* Check to see if active card settings should be shown */}
              {showCardSettings ? (
                <CardSettings
                  setShowCardSettings={setShowCardSettings}
                  selectedCardId={selectedCardId}
                  selectedCard={selectedCard}
                />
              ) : pendingKYB ? (
                <PendingCardsKYBScreen />
              ) : viewAllTransactions ? (
                // show all transactions when "View All" clicked
                <CardTransactionTable
                  viewAllTransactions={viewAllTransactions}
                  setViewAllTransactions={setViewAllTransactions}
                  selectedCardId={selectedCardId}
                />
              ) : (
                <>
                  {/* Cards Header */}
                  {
                    !roles || roles?.length === 0 || !roles?.includes(staticRoles.ACCOUNTANT) ? (
                  <div className="d-flex justify-content-between p-0 m-0 mb-2 w-100">
                    <div className="heading p-0 cardsHeader">
                      {/* <h2 className="generalInfo-heading">
                        {t("leftSidebar.cards")}
                      </h2> */}
                    </div>
                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3 p-0">
                      <IconButton styleClass="btnAddNewCard" prefixIcon={<MdAdd style={{ fontSize: "20px" ,fontWeight: "600",height: "1.3em",width: "1.3em" }} />} text={t('cardsSettings.addNewCard')} onClick={handleApplyCardClick} />
                    </div>
                  </div>
                    ) : null
                    }
                  <div className="cardsParentContainer">
                    {/* Cards */}
                    {
                       !roles || roles?.length === 0 || !roles?.includes(staticRoles.ACCOUNTANT) ? (
                          <div className="allCardsContainer">
                            <div className="cardDiv">
                              {(allCardsSelector.status === "loading" || cardOrdersSelector.status === "loading") ? (
                                <LoadingCardsComponent />
                              ) : (
                                <>
                                  {(cards.length !== 0 || cardOrders.length !== 0) ? (
                                    <>
                                      {cards.length !== 0 && (
                                        //FILTER OUT CARDS WITH THE IRREVERSIBLE STATUSES TO DISPLAY ON THE MAIN CARDS PAGE
                                        cards
                                          .filter((card: FormattedCard) => !["Lost", "Stolen", "Cancelled"].includes(card.status))
                                          .map((card: FormattedCard) => (
                                            <PrepaidCard
                                              activeCard={`${card.cardId === selectedCardId && "selectedCard"}`}
                                              data={card}
                                              key={card.cardId}
                                              onClick={handleCardClick}
                                            />
                                          ))
                                      )}

                                      {cardOrders.length !== 0 && (
                                        <>
                                          {cards.length !== 0 && <Divider orientation="vertical" variant="middle" sx={{ background: 'darkGray', marginRight: "20px" }} />}
                                          {cardOrders
                                            .filter((order: FormattedCardOrder) => !order.cardActivated)    //FILTER OUT ACTIVATED CARD ORDERS
                                            .map((order: FormattedCardOrder) => (
                                              <PrepaidCardOrders
                                                key={order.id}
                                                data={order}
                                              />
                                            ))}
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <NoCardsComponent handleApplyCardClick={handleApplyCardClick} />
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                       ): null
                    }

                    {/* Primary card action buttons */}
                    {
                       !roles || roles?.length === 0 || !roles?.includes(staticRoles.ACCOUNTANT) ? (
                        <Row className="cardPrimaryActions mt-2">
                          {CardPrimaryActions.map((action, index) => (
                            <SettingButtons data={action} key={index} />
                          ))}
                        </Row>
                       ) : null
                    }
                  </div>

                  {/* Show only recent transactions on main cards page */}
                  {!viewAllTransactions &&
                    <CardTransactionTable
                      viewAllTransactions={viewAllTransactions}
                      setViewAllTransactions={setViewAllTransactions}
                      selectedCardId={selectedCardId}
                    />
                  }
                </>
              )}
            </div>
          </div>

          {/* THIS IS THE FOOTER, COMMENTED OUT SINCE NOTHING IS NEEDED RN. KEEP SINCE ALL THE STYLING IS APPLIED TO THIS */}
          {/* <div className="terms-and-conditions position-absolute">
            <p className="p-0 m-0"> This is the footer. </p>
          </div> */}
        </div>

        {/* <RightSideBar
          expense_payments
          summaryBox
          interestAccount
          isApplyForCard={!pendingKYB}
          handleApplyCardClick={handleApplyCardClick}
        /> */}
      </div>

      {showConfirmPassword && (
        <ConfirmPasswordModal
          showConfirmPassword={showConfirmPassword}
          handleClose={handleClose}
          handlePassReauth={handlePassReauth}
          reauthAction="showPAN"
        />
      )}

      {passwordReauth && (
        <CardDetailsModal
          showCardDetails={passwordReauth}
          handleClose={handleClose}
          selectedCardId={selectedCardId}
          selectedCard={selectedCard}
        />
      )}

      {showAddMoney && (
        <AddMoneyModal
          showAddMoneyModal={showAddMoney}
          setShowAddMoney={setShowAddMoney}
          handleClose={handleClose}
          loadCardData={loadCardData}
          setLoadCardData={setLoadCardData}
          setShowAddMoneySummary={setShowAddMoneySummary}
          setSelectedBusinessAcc={setSelectedBusinessAcc}
          selectedBusinessAcc={selectedBusinessAcc}
          selectedCard={selectedCard}
        />
      )}

      {showAddMoneySummary &&
        <TopUpSummaryModal
          showSummaryModal={showAddMoneySummary}
          loadCardData={loadCardData}
          setLoadCardData={setLoadCardData}
          selectedCard={selectedCard}
          handleClose={handleClose}
          selectedBusinessAcc={selectedBusinessAcc}
          setShowAddMoneySummary={setShowAddMoneySummary}
          setShowAddMoney={setShowAddMoney}
        />
      }

      {applyCard && (
        <ApplyForCardModal
          applyCard={applyCard}
          handleClose={handleClose}
          setApplyCard={setApplyCard}
        />
      )}
    </>
  );
};
export default PrepaidCards;


const LoadingCardsComponent = () =>
(
  <div className="cardsSkeletonContainer">
    <Skeleton
      variant="rectangular"
      height={"160px"}
      className="cardBodySkeleton"
    />
    <h3 style={{ color: "rgba(173, 182, 192, 1)" }}>Fetching all your cards...</h3>
  </div>
)

const NoCardsComponent = (props: { handleApplyCardClick: () => void }) => {
  const { t } = useTranslation();
  return (
    <div className="noCardsMessageContainer">
      <div onClick={props.handleApplyCardClick} className="noCardsMessageInnerContainer">
        <img
          src={CardTemplate}
          className={`cardTemplateImage`}
        />
        <h3>{t("cardsSettings.noCardErrorMsg")}</h3>
      </div>
    </div>
  )
}
