import React, { useState } from "react";
import { t } from "i18next";
import { Modal, ModalBody, Spinner } from "react-bootstrap";
import { IconButton, Tooltip } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import cardSelectors from "../../../../redux/toolkit/cards/selector";
import { reauthenticatePassword } from "../../../../redux/toolkit/cards/operation";
import { AppDispatch } from "../../../../redux/store";
import { resetStatus } from "../../../../redux/actions";
import StrongCustomerAuthentication from "../../../../components/shared/StrongCustomerAuthentication";
// import PaymentVerifictionIcon from "../../../../assets/images/payment-verification.png"
import { PaymentVerifictionIcon } from "../../../../constants/Images";

import BasicButton from "../../../../components/shared/Buttons/Basic";
import { ReauthenticatePasswordResponse } from "../../../../redux/toolkit/cards/interface";
import InputTypeText from "../../../../components/shared/Input/Basic/InputTypeText";

const ConfirmPasswordModal = (props: {
  showConfirmPassword: boolean,
  handleClose: () => void,
  setShowUserConfirmation?: any
  setShowConfirmPassword?: any
  handlePassReauth?: any
  reauthAction: string
}) => {
  const { t } = useTranslation();
  const { showConfirmPassword, handleClose, setShowUserConfirmation, setShowConfirmPassword, handlePassReauth, reauthAction } = props;
  const dispatch = useDispatch<AppDispatch>();
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [reauthError, setReauthError] = useState<string>("");
  const [authCompleted, setAuthCompleted] = useState<boolean>(false);

  // This state is set when the component renders, utilize it to conditionally show the SCA accordian
  const [requireSCA] = useState<boolean>(reauthAction === "showPin" || reauthAction === "showPAN");

  const reauthPassSelector = useSelector(cardSelectors.reauthPasswordSelect);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPassword(value);
  };

  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (password === "") {
      setReauthError("Password cannot be empty.")
    } else {
      dispatch(reauthenticatePassword({ userId: localStorage.getItem('userId')!, password: password }))
        .unwrap()
        .then((resp: ReauthenticatePasswordResponse) => {
          // resp.data is the result of the comparison directly from the BE.
          if (resp.data) {
            setReauthError("")
            switch (reauthAction) {
              case "freeze":
                // console.log("User wants to freeze the card.")
                setShowUserConfirmation(true)
                return setShowConfirmPassword(false)
              case "unfreeze":
                // console.log("User wants to UN-freeze the card.")
                setShowUserConfirmation(true)
                return setShowConfirmPassword(false)
              case "showPin":
                // console.log("User wants to view PIN.")
                dispatch(resetStatus());
                setShowConfirmPassword(false)
                return handlePassReauth()
              default:
                // console.log("User wants to view PAN.")
                // setShowConfirmPassword(false)
                dispatch(resetStatus());
                return handlePassReauth()
            }
          } else {
            setReauthError(resp.message)
          }
        })
        .catch((err: any) => {
          console.log("Error occured during password reauthentication:", err);
        })
    }
  }

  const enablePasswordCheck = requireSCA && !authCompleted

  return (
    <Modal
      show={showConfirmPassword}
      onHide={() => {
        dispatch(resetStatus());
        return handleClose()
      }}
      centered>
      <Modal.Header style={{ display: 'flex', alignItems: 'center', borderBottom: 0 }}>
        <div>
          {!PaymentVerifictionIcon && <Modal.Title>{t('cardsSettings.userAuthentication')}</Modal.Title>}
        </div>
        <IconButton
          style={{ backgroundColor: '#006ff4', color: 'white', width: '22px', height: '22px' }}
          onClick={() => {
            dispatch(resetStatus());
            return handleClose()
          }}
        >
          <CloseIcon style={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Modal.Header>
      <ModalBody className="pt-2 pb-2">
        {!requireSCA &&
          <div className="d-flex justify-content-center align-items-center flex-column">
            <div className="mb-2">
              <img src={PaymentVerifictionIcon} width='100' height='100' />
            </div>
            <div className="paymentVerifyText mb-3">
              <h2 className="ver-right-title m-0">{t("cardsSettings.userAuthentication")}</h2>
            </div>
          </div>}

        {/* IN CASES WHERE SCA IS REQUIRED, THIS PROP (requireSCA) IS PASSED AS `TRUE` AND
        A STATE (setAuthCompleted) IS UTILIZED TO KEEP TRACK OF AUTHENTICATION */}
        {requireSCA && <StrongCustomerAuthentication setAuthCompleted={setAuthCompleted} showImage />}

        {/* IN SOME CASES, THE USER MUST ALSO ENTER THEIR PASSWORD AFTER SCA */}
        <p
          // The styling is conditional, if the SCA accordian is show, add a top margin, else don't
          className={`pass-text mb-2 ${requireSCA && `mt-4`}`}
          style={{ fontWeight: '600', margin: 0 }}>
          {t('password')}
        </p>
        <Tooltip title={enablePasswordCheck
          ? `Complete authentication above before proceeding with password verification!`
          : ""}
          placement="top-end"
        >
          <div style={{
            cursor: enablePasswordCheck ? "not-allowed" : ""
          }}>
            <div style={{ position: 'relative' }}>
              <InputTypeText
                type={showPassword ? 'text' : 'password'} // Toggle between 'text' and 'password'
                placeholder={t('enterPass')}
                onChange={handleInputChange}
                errorMsg={reauthError}
                disabled={enablePasswordCheck}
                inputStyle={{
                  height: "50px",
                }}
              />
              <IconButton
                style={{ position: 'absolute', right: '10px', top: '48%', transform: 'translateY(-50%)' }}
                onClick={toggleShowPassword}
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </div>
            <BasicButton
              type="button"
              onClick={onClick}
              disabled={enablePasswordCheck}
              isLoading={reauthPassSelector.status === "loading"}
              text="Confirm"
            />
          </div>
        </Tooltip>
      </ModalBody>
    </Modal >
  )
};

export default ConfirmPasswordModal;

