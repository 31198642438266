import React, { useEffect, useState } from "react";
// import Arrow2 from "../../../assets/images/arrow-send.png";
// import Transfer from "../../../assets/images/Transfer.png";
// import Convert from "../../../assets/images/convert-founds.png";
// import Convert from "../../../assets/images/convert.png";
import BankCard from "../BankCard";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Col, Modal, Row, Spinner, Table, Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { getAllAccounts } from "../../../redux/toolkit/getAllUserAccount/operation";
import { useDispatch } from "react-redux";
import { getCardAccountBalance } from "../../../redux/toolkit/accountBalances/operation";
import "./index.css";
import { SelectedConvert, SelectedTransfer } from "../../../constants/Images";
import ModalHeader from "../Modal/ModalHeader";
import ProofOfAccountModal from "../PoaModal";


const CardDetails = () => {
  const dispatch = useDispatch<AppDispatch>();
  const BalanceSelector: any = useSelector(
    (state: RootState) => state.getAllAccountBalance.data
  );
  const [show, setShow] = useState(false);
  const [cardDetails, setCardDetails] = useState<[]>([]);
  const [balance, setBalance] = useState("");
  const [flag, setFlag] = useState(false);
  const [bankCode, setBankCode] = useState("");
  const [loader, setLoader] = useState(false);
  const [showPoaModal, setshowPoaModal] = useState<boolean>(false);
  const [accountId, setAccountId] = useState<any>(
    localStorage.getItem("accountId")
  );
  const accountSelector: any = useSelector(
    (state: RootState) => state.getAllAccounts.data
  );
  const accountData: any = localStorage.getItem("AccountData");
  const parsedData: any = JSON.parse(accountData);
  const { t } = useTranslation();

  const navigate = useNavigate();
  useEffect(() => {
    if (parsedData?.IBAN.slice(0, 2) === "DK") {
      setBankCode(parsedData?.IBAN.slice(4, 8));
      setFlag(true);
    } else {
      setFlag(false);
    }
  }, [bankCode, flag]);

  useEffect(() => {
    dispatch(getAllAccounts(accountId));
  }, [dispatch]);

  useEffect(() => {
    if (accountSelector?.data) {
      setCardDetails(accountSelector?.data.data);
    }
  }, [accountSelector, cardDetails]);

  useEffect(() => {
    dispatch(getCardAccountBalance(accountId)).then(() => setLoader(true));
    setBalance("");
  }, [dispatch, accountId]);

  useEffect(() => {
    if (BalanceSelector?.data) {
      setBalance(BalanceSelector?.data.data?.float);
    }
    setLoader(false);
  }, [BalanceSelector, loader]);

  useEffect(() => {
    document.addEventListener("CardValue", (event: any) => {
      const { detail } = event;
      setAccountId(detail.accountId);
    });
  }, []);

  const eyeClick = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };


  const handleViewAccClick = () => {
    setshowPoaModal(true);
  };
  const handleCloseViewAccClick = () => {
    setshowPoaModal(false);
  };

  return (
    <>
    <Row className="generalInfoDiv">
      <Col sm={12} md={12} lg={12} xl={12}>
        <div className="accountBalance">
          <p className="balanceTitle">
            {t("cardDetails.balance")}
          </p>
          <p className="amount">{balance === "" ? <Spinner size="sm"/> : balance}</p>
          <p className="currency">{parsedData?.currency}</p>
        </div>
      </Col>
      
      <Col xs={6} sm={12} md={12} lg={7} xl={7} className="p-0">
        <BankCard
          ibanNo={parsedData?.IBAN}
          currency={parsedData?.currency}
          handleEyeClick={eyeClick}
          name={parsedData?.accountName}
          handleViewAccClick={handleViewAccClick}
        />
      </Col>

      <Col xs={6} sm={12} md={12} lg={5} xl={5} className="transferMoneyCard-col mt-md-2 mt-lg-1">
        <Card
          className="AccountInfoActionCards transferMoneyCard"
          onClick={() => navigate("/sendFunds")}
        >
          <Card.Body>
            <Row className="align-items-center">
              <Col xs={3} sm={2} md={2} lg={2} xl={2}>
                <div className="actionCardImg">
                  <img
                    className="balance-plus-btn"
                    src={SelectedTransfer}
                    alt="plus"
                  />
                </div>
              </Col>
              <Col xs={9} sm={10} md={10} lg={10} xl={10}>
                <p className="actionCardTitle m-0 p-2 ps-0 ps-md-3">{t("cardDetails.transfer")}</p>
              </Col>
            </Row>
            {/* <Button variant="primary">Go somewhere</Button> */}
          </Card.Body>
        </Card>
        <Card
          className="AccountInfoActionCards convertFundsCard"
          onClick={() => navigate("/convert")}
        >
          <Card.Body>
            <Row className="align-items-center">
              <Col xs={3} sm={2} md={2} lg={2} xl={2} className="convert-funds-col">
                <div className="actionCardImg">
                  <img
                    className="balance-plus-btn"
                    src={SelectedConvert}
                    alt="plus"
                  />
                </div>
              </Col>
              <Col xs={9} sm={10} md={10} lg={10} xl={10}>
                <p className="actionCardTitle m-0 p-2 ps-0 ps-md-3">{t("cardDetails.convert")}</p>
              </Col>
            </Row>
            {/* <Button variant="primary">Go somewhere</Button> */}
          </Card.Body>
        </Card>
      </Col>
      </Row>

      {/* <Col sm={12} md={12} lg={2} xl={2}>
        <p className="card-balance-text">{t("cardDetails.balance")}</p>
                <div className="acc-balance">
                  <p className="balance">{loader ? <Spinner /> : balance}</p>
                  <p className="balance-currency">{parsedData?.currency}</p>
                </div>
        </Col> */}

      
        <Modal
          style={{ marginTop: 100 }}
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          {/* <Modal.Header closeButton>
            <Modal.Title>{t("cardDetails.modal.title")}</Modal.Title>
          </Modal.Header> */}
          <ModalHeader title={t("cardDetails.modal.title")} onClose={() => setShow(false)}/> 
          <Modal.Body>
            <Table>
              <tbody>
                <tr>
                  <td style={{ fontWeight: "bold" }}>
                    {t("cardDetails.modal.bank")}:
                  </td>
                  <td>{parsedData?.bank}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold" }}>
                    {t("cardDetails.modal.address")}:
                  </td>
                  <td>{parsedData?.bankAddress}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold" }}>
                    {t("cardDetails.modal.name")}:
                  </td>
                  <td>{parsedData?.accountName}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold" }}>
                    {t("cardDetails.modal.sortCode")}:
                  </td>
                  <td>{parsedData?.sortCode}</td>
                </tr>
                {flag ? (
                  <tr>
                    <td style={{ fontWeight: "bold" }}>
                      {t("cardDetails.modal.bankCode")}:
                    </td>
                    <td>{bankCode}</td>
                  </tr>
                ) : (
                  ""
                )}
                <tr>
                  <td style={{ fontWeight: "bold" }}>
                    {t("cardDetails.modal.accountNo")}:
                  </td>
                  <td>
                    {flag
                      ? parsedData?.IBAN.slice(8)
                      : parsedData?.accountNumber}
                  </td>
                </tr>

                <tr>
                  <td style={{ fontWeight: "bold" }}>
                    {t("cardDetails.modal.iban")}:
                  </td>
                  <td>{parsedData?.IBAN}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold" }}>
                    {t("cardDetails.modal.swiftCode")}:
                  </td>
                  <td>{parsedData?.swiftCode}</td>
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
        </Modal>



        {showPoaModal && (
        <ProofOfAccountModal
        showPoaModal={showPoaModal}
          handleClose={handleCloseViewAccClick}
          
        />
      )}
    </>
  );
};

export default CardDetails;