import * as React from 'react';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import '../index.css'

type InputProps = {
  disabled?: boolean;
  inputStyle?: object;
  name?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: any;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string | any;
  readOnly?: boolean;
  required?: boolean;
  autoFocus?: boolean;
  type?: string;
  step?: string;
  ref?: any| undefined;
  value?: string ;
  defaultValue?: string;
  className?: string;
  errorMsg?: string;
  errorMsgColor?: string;
  min?: string;
  max?: string;
  compulsoryValue?: boolean;
  maxLength?: number;
  minLength?: number;
  id?: string;
  inputContainerMargins?: string
  inputData:any
  onInputChange?:any
};


export default function AutoCompleteInput(props: InputProps) {
  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = useState(''); // Manage inputValue as state


  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: any) => option?.label || '',
  });

  return (
    <>
    <Autocomplete
      filterOptions={filterOptions}
      className='auto-complete-input'
      open={open}
      onClose={() => setOpen(false)}
      disablePortal
      id="combo-box-demo"
      options={props.inputData}
      value={props.value?.toString() || ''}
      defaultValue={null}
      inputValue={props.value} // Control the inputValue
      sx={{ width: "100%" , border: "2px solid #D7F0FF", borderRadius: "10px", "& fieldset": { border: 'none' }}}
      renderInput={(params) => <TextField {...params} label="" />}
      onInputChange={(event, newInputValue:string) => {
        setInputValue(newInputValue); // Update inputValue state
        if (newInputValue.length === 0) {
          if (open) setOpen(false);
        } else {
          if (!open) setOpen(true);
        }
        props.onChange(newInputValue);
      }}
      onBlur={props.onBlur}
      // onChange={props.onChange}
      
      freeSolo
    />
    {props.errorMsg ? (
        <span
          style={{ color: props.errorMsgColor ? props.errorMsgColor : "red" }}
        >
          {props.errorMsg}
        </span>
      ) : (
        ""
      )}
      </>
    
  );
}

const top100Films = [
  { label: 'The Dark Knight', year: 2008 },
  { label: '12 Angry Men', year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: 'Pulp Fiction', year: 1994 },
  { label: 'Forrest Gump', year: 1994 },
  { label: 'Inception', year: 2010 },
  { label: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { label: 'Goodfellas', year: 1990 },
  { label: 'The Matrix', year: 1999 },
  { label: 'Seven Samurai', year: 1954 },
  { label: 'City of God', year: 2002 },
  { label: 'Se7en', year: 1995 },
  { label: 'The Silence of the Lambs', year: 1991 },
  { label: "It's a Wonderful Life", year: 1946 },
  { label: 'Life Is Beautiful', year: 1997 },
  { label: 'The Usual Suspects', year: 1995 },
  { label: 'Léon: The Professional', year: 1994 },
  { label: 'Spirited Away', year: 2001 },
  { label: 'Saving Private Ryan', year: 1998 },
  { label: 'Once Upon a Time in the West', year: 1968 },
  { label: 'American History X', year: 1998 },
  { label: 'Interstellar', year: 2014 },
  { label: 'Casablanca', year: 1942 },
  { label: 'City Lights', year: 1931 },
  { label: 'Psycho', year: 1960 },
  { label: 'The Green Mile', year: 1999 },
  { label: 'The Intouchables', year: 2011 },
  { label: 'Modern Times', year: 1936 },
  { label: 'Raiders of the Lost Ark', year: 1981 },
  { label: 'Rear Window', year: 1954 },
  { label: 'The Pianist', year: 2002 },
  { label: 'The Departed', year: 2006 },
  { label: 'Terminator 2: Judgment Day', year: 1991 },
  { label: 'Back to the Future', year: 1985 },
  { label: 'Whiplash', year: 2014 },
  { label: 'Gladiator', year: 2000 },
  { label: 'Memento', year: 2000 },
  { label: 'The Prestige', year: 2006 },
  { label: 'The Lion King', year: 1994 },
  { label: 'Apocalypse Now', year: 1979 },
  { label: 'Alien', year: 1979 },
  { label: 'The Great Dictator', year: 1940 },
  { label: 'Cinema Paradiso', year: 1988 },
  { label: 'The Lives of Others', year: 2006 },
  { label: 'Grave of the Fireflies', year: 1988 },
  { label: 'Paths of Glory', year: 1957 },
  { label: 'Django Unchained', year: 2012 },
  { label: 'The Shining', year: 1980 },
  { label: 'WALL·E', year: 2008 },
  { label: 'American Beauty', year: 1999 },
  { label: 'The Dark Knight Rises', year: 2012 },
  { label: 'Princess Mononoke', year: 1997 },
  { label: 'Aliens', year: 1986 },
  { label: 'Oldboy', year: 2003 },
  { label: 'Once Upon a Time in America', year: 1984 },
  { label: 'Witness for the Prosecution', year: 1957 },
  { label: 'Das Boot', year: 1981 },
  { label: 'Citizen Kane', year: 1941 },
  { label: 'North by Northwest', year: 1959 },
  { label: 'Logan', year: 2017 },
  { label: 'Full Metal Jacket', year: 1987 },
  { label: 'Dangal', year: 2016 },
  { label: 'The Sting', year: 1973 },
  { label: '2001: A Space Odyssey', year: 1968 },
  { label: "Singin' in the Rain", year: 1952 },
  { label: 'Toy Story', year: 1995 },
  { label: 'Bicycle Thieves', year: 1948 },
  { label: 'The Kid', year: 1921 },
  { label: 'Inglourious Basterds', year: 2009 },
  { label: 'Snatch', year: 2000 },
  { label: '3 Idiots', year: 2009 },
  { label: 'Monty Python and the Holy Grail', year: 1975 },
];
