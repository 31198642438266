import React, { useEffect, useState } from "react";
import { Modal, ModalBody, Spinner } from "react-bootstrap";
import { IconButton, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { initiateCardPaymentDetails } from "../../../../redux/toolkit/cards/operation";
import { AppDispatch } from "../../../../redux/store";
import "./index.css";
import CardTemplate from "../../../../assets/images/cardAssets/omwCardTemplate.svg"
// import CardTemplate from "../../../../assets/images/cardAssets/omwCardTemplateNew.svg"
import { FormattedCard } from "../../interface";
import axios from "axios";
import { CardPaymentDetailsTokenResponse } from "../../../../redux/toolkit/cards/interface";
import { IoCopyOutline, IoCheckboxOutline } from "react-icons/io5";

// Type for card PAN
type CardPAN = {
  pan: string;
  cvv: string;
  exp: string;
};

// Type for card PAN response from B4B
type CardPANResponse = {
  status: number;
  data: CardPAN;
};

const CardDetailsStagingEndpoint = `https://staging-lambda.b4bpayments.com/pan`;
const CardDetailsProductionEndpoint = `https://lambda.b4bpayments.com/pan`;

const CardDetailsEndpoint = process.env.REACT_APP_ENV === 'production' ? CardDetailsProductionEndpoint : CardDetailsStagingEndpoint

const CardDetailsModal = (props: {
  showCardDetails: boolean;
  selectedCardId: string;
  selectedCard: FormattedCard;
  handleClose: () => void;
}) => {
  const { showCardDetails, selectedCardId, selectedCard, handleClose } = props;
  const { t } = useTranslation();
  const [cardPAN, setCardPAN] = useState<CardPAN>({
    pan: "",
    cvv: "",
    exp: ""
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);                            // Add isLoading state
  const [copied, setCopied] = useState<"name" | "pan" | "expiry" | "cvv" | "">("");     // updates which icon was pressed to copy card details
  const dispatch = useDispatch<AppDispatch>();

  // get JWT token generated from the BE for card payment details
  useEffect(() => {
    dispatch(
      initiateCardPaymentDetails({
        userId: localStorage.getItem("userId")!,
        cardId: selectedCardId,
      })
    )
      .unwrap()
      .then(async (resp: CardPaymentDetailsTokenResponse) => { // Make the callback async
        try {
          const panResp: CardPANResponse = await axios.get(
            CardDetailsEndpoint,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${resp.data}`,
              },
            }
          );
          if (panResp.status === 200) {
            setCardPAN(panResp.data);
          }
        } catch (error) {
          console.log("Error fetching PAN:", error);
        } finally {
          setIsLoading(false); // Set isLoading to false when the data is ready
        }
      })
      .catch((err) => {
        console.log("Error fetching card token:", err);
        setIsLoading(false); // Set isLoading to false in case of an error
      });
  }, []);

  function cardNumberFormatter(cardNumber: string): string {
    if (typeof cardNumber !== 'string' || cardNumber.length !== 16) {
      // Handle invalid input
      return "**** **** **** ****";
    }

    const formattedCardNumber = cardNumber.replace(/(\d{4})/g, '$1 ');
    return formattedCardNumber.trim(); // Remove trailing space
  }

  const formatExpiryDate = (date: string): string => {
    const [year, month] = date.split("-");
    return `${month}/${year.slice(-2)}`;
  };

  const copyToClipboard = (textToCopy: string, copyValue: "name" | "pan" | "expiry" | "cvv" | "") => {
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        if (copyValue !== "") {
          setCopied(copyValue);
        } else {
          setCopied("");
        }
      })
      .catch((error) => {
        setCopied("");
      });
  };

  return (
    <Modal
      show={showCardDetails}
      centered
      onHide={() => {
        setCardPAN({
          pan: "",
          cvv: "",
          exp: ""
        });
        return handleClose();
      }}
      className="mt-4"
    >
      <Modal.Header
        style={{ display: "flex", alignItems: "center", borderBottom: 0 }}
      >
        <div>
          <Modal.Title>Card Payment Details</Modal.Title>
        </div>
        <IconButton
          style={{
            backgroundColor: "#006ff4",
            color: "white",
            width: "22px",
            height: "22px",
          }}
          onClick={() => {
            setCardPAN({
              pan: "",
              cvv: "",
              exp: ""
            });
            return handleClose();
          }}
        >
          <CloseIcon style={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Modal.Header>
      <ModalBody className="pt-0 pb-3">
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
          {isLoading ? (
            <div className="loader">
              <Spinner animation={"border"} />
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", width: "290px" }}>
              <div className="availableAmountDiv">
                <p className="availableAmountLabel">Available Amount</p>
                <p className="availableAmount">{selectedCard.availableBalance} {selectedCard.currency}</p>
              </div>
              <img
                className="cardTemplateViewDetails"
                src={CardTemplate}
              />
              <div className="cardDetailsDiv">
                {/* CARD DETAILS LABELS::: START */}
                <div>
                  <p className="cardDetailsLabel m-0">Cardholder Name</p>
                  <p className="cardDetailsLabel m-0">PAN Number</p>
                  <p className="cardDetailsLabel m-0">Expiry Date</p>
                  <p className="cardDetailsLabel m-0">CVV</p>
                </div>
                {/* CARD DETAILS LABELS::: END */}

                {/* CARD DETAILS SECTION::: START */}
                <div>
                  <p className="cardDetailsValue m-0">{selectedCard.embossedName}</p>
                  {/* <p className="cardDetailsValue m-0">1234 5678 9123 4567</p> */}
                  <p className="cardDetailsValue m-0">
                    {cardNumberFormatter(cardPAN.pan)}
                  </p>
                  <p className="cardDetailsValue m-0">{cardPAN.exp ? formatExpiryDate(cardPAN.exp) : formatExpiryDate(selectedCard.expiry)}</p>
                  <p className="cardDetailsValue m-0">{cardPAN.cvv || `***`}</p>
                </div>
                {/* CARD DETAILS SECTION::: END */}

                {/* COPY DETAILS ICONS SECTION::: START */}
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "4px", cursor: "pointer" }}>
                  {copied === "name"
                    ? <IoCheckboxOutline style={{ color: "gray" }} />
                    : <IoCopyOutline style={{ color: "gray" }} onClick={() => copyToClipboard(selectedCard.embossedName, "name")} />
                  }
                  {copied === "pan"
                    ? <IoCheckboxOutline style={{ color: "gray" }} />
                    : <IoCopyOutline style={{ color: "gray" }} onClick={() => copyToClipboard(cardNumberFormatter(cardPAN.pan), "pan")} />
                  }
                  {copied === "expiry"
                    ? <IoCheckboxOutline style={{ color: "gray" }} />
                    : <IoCopyOutline style={{ color: "gray" }} onClick={() => copyToClipboard(cardPAN.exp ? formatExpiryDate(cardPAN.exp) : formatExpiryDate(selectedCard.expiry), "expiry")} />
                  }
                  {copied === "cvv"
                    ? <IoCheckboxOutline style={{ color: "gray" }} />
                    : <IoCopyOutline style={{ color: "gray" }} onClick={() => copyToClipboard(cardPAN.cvv || `658`, "cvv")} />
                  }
                </div>
                {/* COPY DETAILS ICONS SECTION::: END */}
              </div>
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CardDetailsModal;