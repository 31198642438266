import React from "react";
import { useTranslation } from "react-i18next";
import AccountSwitch from "../../components/shared/AccountsSwitch";
import CardDetails from "../../components/shared/CardDetails";
import Footer from "../../components/shared/Footer";
import HamburgerMenu from "../../components/shared/HamburgerMenu";
import LeftSideBar from "../../components/shared/LeftSidebar";
import RightSideBar from "../../components/shared/RightSidebar";
import "./index.css";
import InterestAccountCard from "../../components/shared/InterestAccountCard";
import { Col, Row } from "react-bootstrap";
import ExchangeRatesCard from "../../components/shared/ExchangeRatesCard";
import { CardWhiteIcon } from "../../constants/Images";

const GeneralInformation = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* <div className="hamburgerBackground">
        <HamburgerMenu name="generalInfo" />
      </div> */}

      <Row className="d-flex justify-content-between m-1 m-sm-3">
        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-4 p-0">
          <div className="accountSwitch">
            <AccountSwitch />
          </div>

          {/* <div className="createNewAccount d-flex justify-content-between align-items-center">
            <div className="accountIcon">
              <img src={CardWhiteIcon}/>
            </div>
            <div className="accountText">{t("generalInfo.account")}</div>
            <div className="openCloseArrow"></div>
          </div> */}
        </Col>
        <Col xs={12} sm={12} md={7} lg={8} xl={8} className="cardDetailsBox mb-4 pe-sm-4">
          {/* <div className="Acc-switch">
            <AccountSwitch />
          </div> */}
          <CardDetails />
        </Col>
        <Col md={5} lg={4} xl={4} className="MonthlyRevenueTracker">
          {/* <RightSideBar exchangeRates summaryBox /> */}
          <ExchangeRatesCard />
        </Col>
      </Row>
    </>
  );
};

export default GeneralInformation;
